import React, { useEffect } from 'react';
import { useNavigate, Outlet, Navigate } from 'react-router-dom';

import { useRecoilValue } from 'recoil';
import { userState } from "../../recoil/atom";

const LoginRoute = () => {
  const user = useRecoilValue(userState);
  const navigate = useNavigate();

  useEffect( () => {
    if(user.isSignedIn){
      navigate('/');
    }
  }, []);

  if(user.isSignedIn){
    return <Navigate to="/" replace />
  }
  else{
    return <Outlet />;
  }
};

export default LoginRoute;