import React from "react"
import { styled } from '@mui/material/styles'
import { Checkbox } from "@mui/material"

// チェックボックスの見た目をカスタマイズする（予定）

const CustomCheckbox = (props) => {

  return (
    <Checkbox
    {...props}
    />
  )
}

export default CustomCheckbox