import axios from "axios";
import { config } from '../lib/config'

const getInstitutions = async () => {
  const { data } = await axios.get(`${config.api_url}/institutions`)
  return data
}

const getInstitution = async (id) => {
  const { data } = await axios.get(`${config.api_url}/institutions/${id}`)
  return data
}

const createInstitution = async (formdata) => {
  // console.log('createInstitution:',formdata)
  const { data } = await axios.post(
    `${config.api_url}/institutions`,
    formdata
  )
  return data
}

const updateInstitution = async (formdata) => {
  const { data } = await axios.patch(
    `${config.api_url}/institutions/${formdata.id}`,
    formdata
  )
  return data
}

const deleteInstitution = async (formdata) => {
  const {data} = await axios.delete(
    `${config.api_url}/institutions/${formdata.id}`,
    formdata
  )
  return data
}

export {
  getInstitutions,
  getInstitution,
  createInstitution,
  updateInstitution,
  deleteInstitution
}