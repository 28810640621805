import { styled } from '@mui/material/styles';
import {
  Select, MenuItem
} from '@mui/material'


const CustomMenuItem = styled(MenuItem)({
  fontWeight: 'bold',
  "&.gray": {
    backgroundColor: 'gray',
    color: 'white',
  },
  "&.pink": {
    backgroundColor: 'pink',
  },
  "&.red": {
    backgroundColor: 'red',
    color: 'white',
  },
  "&.purple": {
    backgroundColor: 'purple',
    color: 'white',
  },
  "&.yellow": {
    backgroundColor: 'yellow',
  },
  "&.white": {
    backgroundColor: 'white',
  },
  "&.blue": {
    backgroundColor: 'blue',
    color: 'white',
  },
  "&.orange": {
    backgroundColor: 'orange',
  },
  "&.green": {
    backgroundColor: 'green',
    color: 'white',
  },
})
const CustomSelect = styled(Select)({
  fontWeight: 'bold',
  "em": {
    fontWeight: 'normal',
  },
  "&.gray": {
    backgroundColor: 'gray',
    color: 'white',
  },
  "&.pink": {
    backgroundColor: 'pink',
  },
  "&.red": {
    backgroundColor: 'red',
    color: 'white',
  },
  "&.purple": {
    backgroundColor: 'purple',
    color: 'white',
  },
  "&.yellow": {
    backgroundColor: 'yellow',
  },
  "&.white": {
    backgroundColor: 'white',
  },
  "&.blue": {
    backgroundColor: 'blue',
    color: 'white',
  },
  "&.orange": {
    backgroundColor: 'orange',
  },
  "&.green": {
    backgroundColor: 'green',
    color: 'white',
  },
})

export {
  CustomMenuItem,
  CustomSelect,
}