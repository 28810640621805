import React, { useState, useEffect } from "react"
import GenericTemplate from '../templates/GenericTemplate'
import {
  Paper, Grid, Stack, Divider,
  Dialog, DialogTitle, DialogContent, DialogActions, IconButton, 
  Collapse, Checkbox,
  FormControl, FormLabel, FormGroup, FormControlLabel, TextField, Button
} from '@mui/material'
import { DataGrid, GridToolbar, jaJP } from '@mui/x-data-grid';
import { useInstitutions, useInstitution, useCreateInstitution, useUpdateInstitution, useDeleteInstitution } from '../../queries/InstitutionQuery';


const Institution = () => {
  const default_form_data = {
    id: '',
    name: '',
  }
  const [open, setOpen] = useState(false)
  const [checked, setChecked] = useState(false)
  const [openDel, setOpenDel] = useState(false)
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
    },
    {
      field: 'name',
      headerName: '名称',
      width: 300,
    },
  ]
  const [rows, setRows] = useState([])
  const [currentId, setCurrentId] = useState(-1)
  const { data, status } = useInstitutions()
  const { data : current, status : current_status } = useInstitution(currentId)
  const [formData, setFormData] = useState(default_form_data)
  const {isSuccess, mutate} = useCreateInstitution()
  const {isSuccess: isUpdateSuccess, mutate: updateMutate} = useUpdateInstitution()
  const {isSuccess: isDeleteSuccess, mutate: deleteMutate} = useDeleteInstitution()

  const handleClickOpen = (id = '') => {
    if(id!==''){
      setCurrentId(id)
      // すでに取得済みの場合
      if(current_status === 'success'){
        setFormData(current)
      }
    }
    setOpen(true);
  }
  const handleClose = () => {
    setCurrentId(-1)
    setFormData(default_form_data)
    setChecked(false)
    setOpen(false)
  }
  const handleChange = (event) => {
    setFormData((old) => { return {...old, [event.target.name]: event.target.value}})
  }
  const handleSave = () => {
    if(formData.id===''){
      mutate(formData)
    }
    else{
      updateMutate(formData)
    }
  }

  const handleDelChange = (event) => {
    setChecked(event.target.checked)
  }
  const handleDelete = () => {
    deleteMutate(formData)
  }

  useEffect(() => {
    console.log('data:',data, status)
    if(status === 'success'){
      setRows(data)
    }
  },[data])
  useEffect(()=>{
    if(current_status === 'success'){
      setFormData(current)
    }
  },[current])
  useEffect(() => {
    if(isSuccess){
      handleClose()
    }
  },[isSuccess])
  useEffect(() => {
    if(isUpdateSuccess){
      handleClose()
    }
  },[isUpdateSuccess])
  useEffect(() => {
    if(isDeleteSuccess){
      handleClose()
    }
  },[isDeleteSuccess])

  return (
    <GenericTemplate title="施設管理" is_top={true}>
      <Grid item xs={12}>
        <Paper sx={{p: 3}}>
          <Button variant='contained' sx={{mb:3}} onClick={() => handleClickOpen()}>新規</Button>
          <DataGrid
            autoHeight
            components={{ Toolbar: GridToolbar }}
            rows={rows}
            columns={columns}
            onRowClick={(params) => handleClickOpen(params.row.id)}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          />
        </Paper>
      </Grid>

      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>施設情報</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt:2, mb:2 }}>
            <FormLabel htmlFor="institution_name">名称</FormLabel>
            <TextField
              id="institution_name"
              required
              value={formData.name??''}
              name="name"
              onChange={handleChange}
            />
          </FormControl>
          {formData.id!=='' && formData.id!==1 && false/* 一旦非表示 */ && <>
            <FormGroup>
              <FormControlLabel control={<Checkbox
                checked={checked}
                onChange={handleDelChange}
              />} label="この施設を削除する" />
            </FormGroup>
            <Collapse in={checked}>
              <Button variant="contained" color="error" onClick={handleDelete}>削除（この操作はキャンセルできません）</Button> 
            </Collapse>
          </>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button variant="contained" onClick={handleSave} disabled={checked}>保存</Button>
        </DialogActions>
      </Dialog>

    </GenericTemplate>
  )
}

export default Institution