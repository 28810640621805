import React, { useEffect } from 'react';
import { useNavigate, Outlet, Navigate } from 'react-router-dom';

import { useRecoilValue } from 'recoil';
import { userState } from "../../recoil/atom";

const GuardRoute = () => {
  const user = useRecoilValue(userState);
  const navigate = useNavigate();

  useEffect( () => {
    // console.log(user);
    if(!user.isSignedIn && user.active!==1){
      navigate('/signin');
    }
  }, []);

  if(!user.isSignedIn && user.active!==1){
    return <Navigate to="/signin" replace />
  }
  else{
    return <Outlet />;
  }
};

export default GuardRoute;