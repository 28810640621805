import * as api from "../api/PpatsApi"
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from 'react-toastify'

const usePpatss = (id) => {
  return useQuery(['ppatss', id], () => api.getPpatss(id))
}

const usePpats = (id) => {
  // console.log('use ppats:', id)
  return useQuery(
    ['ppats', id],
    () => api.getPpats(id),
    { enabled: id > 0 }
  )
}

const useCreatePpats = (props) => {
  const queryClient = useQueryClient()
  // console.log(props)
  return useMutation(api.createPpats, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('ppatss')
      queryClient.invalidateQueries('patient_state')
      // handleClose()
      toast.success('登録が成功しました')
    },
    onError: (error) => {
      toast.error('登録に失敗しました')
    },
  })
}

const useUpdatePpats = (id) => {
  const queryClient = useQueryClient()
  return useMutation(api.updatePpats, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('ppatss')
      queryClient.invalidateQueries('patient_state')
      toast.success('更新が成功しました')
    },
    onError: (error) => {
      toast.error('更新に失敗しました')
    },
  })
}

const useSelectOptions = () => {
  console.log('get select options')
  return useQuery('select_options', () => api.getSelectOptions())
}

export {
  usePpatss,
  usePpats,
  useCreatePpats,
  useUpdatePpats,
  useSelectOptions,
}