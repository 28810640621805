import * as api from "../api/PatientApi"
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'

const usePatients = () => {
  return useQuery('patients', () => api.getPatients())
}

const usePatient = (id) => {
  return useQuery('patient', () => api.getPatient(id))
}

const usePatientState = (id) => {
  return useQuery(
    'patient_state',
    () => api.getPatientState(id),
    { enabled: id > 0 }
  )
}

const usePatientList = (id) => {
  return useQuery(
    'patient_list',
    () => api.getPatientList(id),
    { enabled: id > 0 }
  )
}

const useCreatePatient = (formdata) => {
  const navigate = useNavigate();
  return useMutation(api.createPatient, {
    onSuccess: (data) => {
      console.log('ok:',data)
      toast.success('登録が成功しました')
      navigate(`/${data.id}`)
    },
    onError: (error) => {
      // console.log('error:',error)
      toast.error('登録に失敗しました')
    },
  })
}

const useUpdatePatient = (id) => {
  const queryClient = useQueryClient()
  return useMutation(api.updatePatient, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('patient')
      toast.success('更新が成功しました')
    },
    onError: (error) => {
      toast.error('更新に失敗しました')
    },
  })
}

export {
  usePatients,
  usePatient,
  usePatientState,
  usePatientList,
  useCreatePatient,
  useUpdatePatient,
}