import React, {useState, useEffect} from "react"
import { useNavigate } from 'react-router-dom';
import {
  Box, Stack, Container, Grid,
  AppBar, Toolbar,
  Link, Menu as MenuBox, MenuItem,
  IconButton,
  Typography, Avatar
} from '@mui/material'
import { Menu as MenuIcon, Home as HomeIcon } from '@mui/icons-material'
import Menu from '../templates/Menu'
import Copyright from '../templates/Copyright'

import { useLogout } from '../../queries/AuthQuery'

import { useRecoilValue } from 'recoil';
import { userState } from "../../recoil/atom";

const GenericTemplate = (props) => {
  const navigate = useNavigate()
  const {title, children, is_top = false, others} = props
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const user = useRecoilValue(userState)

  const logout = useLogout()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const clickLogout = () => {
    logout.mutate()
  }
  useEffect(() => {
    console.log('user state', user)
  },[user])

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="absolute">
        <Toolbar>
          <IconButton color="inherit" onClick={() => navigate('/')}>
            <HomeIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            <Link
              onClick={() => navigate('/')}
              component="button"
              variant="h6"
              underline="none"
              sx={{color:'white',verticalAlign:'middle'}}
            >
              小児傷病者診療ウェブシステム
            </Link>
            ［{title}］
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar></Avatar>
            <Typography>{user.institution.name} {user.name}{user.role}</Typography>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleClick}
              sx={{
                marginRight: '36px',
              }}
            >
              <MenuIcon />
            </IconButton>
          </Stack>
          <MenuBox
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={() => navigate('/')}>患者一覧</MenuItem>
            { user.admin===1 && <MenuItem onClick={() => navigate('/admin')}>ユーザ管理</MenuItem>}
            { user.institution_id===1 && <MenuItem onClick={() => navigate('/institution')}>施設管理</MenuItem>}
            <MenuItem onClick={clickLogout}>ログアウト</MenuItem>
          </MenuBox>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          {!is_top && <Grid sx={{mb: 2}}>
            <Menu />
          </Grid>}
          <Grid container spacing={3}>
            {children}
          </Grid>
          <Copyright sx={{ pt: 5 }} />
        </Container>
      </Box>
    </Box>
  )
}

export default GenericTemplate