import React, { useState, useEffect } from "react"
import { useRecoilValue } from 'recoil';
import { userState } from "../../recoil/atom";
import GenericTemplate from '../templates/GenericTemplate'
import {
  Paper, Grid, Stack, Divider,
  Dialog, DialogTitle, DialogContent, DialogActions, IconButton, 
  Collapse, Checkbox, Select, MenuItem,
  FormControl, FormLabel, FormGroup, FormControlLabel, TextField, Button
} from '@mui/material'
import { DataGrid, GridToolbar, jaJP } from '@mui/x-data-grid';
import { useUsers, useUser, useCreateUser, useUpdateUser, useDeleteUser } from '../../queries/UserQuery';
import { useInstitutions } from '../../queries/InstitutionQuery';


const Admin = () => {
  const default_form_data = {
    id: '',
    institution_id: '',
    loginid: '',
    name: '',
    password: '',
    role: '',
    admin: false,
    active: true,
  }
  const [open, setOpen] = useState(false)
  const [checked, setChecked] = useState(false)
  const [openDel, setOpenDel] = useState(false)
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
    },
    {
      field: 'institution',
      headerName: '所属',
      width: 300,
      valueGetter: (params) => {
        return params.row.institution.name
      } 
    },
    {
      field: 'name',
      headerName: '名前',
      width: 200,
    },
    {
      field: 'role',
      headerName: '役職名',
      width: 200,
    },
  ]
  const [rows, setRows] = useState([])
  const [currentId, setCurrentId] = useState(-1)
  const { data : institutions , status : institutions_status } = useInstitutions()
  const { data, status } = useUsers()
  const { data : current, status : current_status } = useUser(currentId)
  const [formData, setFormData] = useState(default_form_data)
  const {isSuccess, mutate} = useCreateUser()
  const {isSuccess: isUpdateSuccess, mutate: updateMutate} = useUpdateUser()
  const {isSuccess: isDeleteSuccess, mutate: deleteMutate} = useDeleteUser()
  const user = useRecoilValue(userState);

  const handleClickOpen = (id = '') => {
    if(id!==''){
      setCurrentId(id)
      // すでに取得済みの場合
      if(current_status === 'success'){
        setFormData((old) => { return {...old, ...current} })
      }
    }
    setOpen(true);
  }
  const handleClose = () => {
    setCurrentId(-1)
    setFormData(default_form_data)
    setChecked(false)
    setOpen(false)
  }
  const handleChange = (event) => {
    setFormData((old) => { return {...old, [event.target.name]: event.target.value}})
  }
  const handleChangeChkbox = (event) => {
    setFormData((old) => { return {...old, [event.target.name]: event.target.checked}})
  }
  const handleSave = () => {
    // console.log('save',formData)
    if(formData.id===''){
      mutate(formData)
    }
    else{
      updateMutate(formData)
    }
  }

  const handleDelChange = (event) => {
    setChecked(event.target.checked)
  }
  const handleDelete = () => {
    deleteMutate(formData)
  }

  useEffect(() => {
    // console.log('data:',data, status)
    if(status === 'success'){
      setRows(data)
    }
  },[data])
  useEffect(()=>{
    if(current_status === 'success'){
      setFormData((old) => { return {...old, ...current} })
    }
  },[current])
  useEffect(() => {
    if(isSuccess){
      handleClose()
    }
  },[isSuccess])
  useEffect(() => {
    if(isUpdateSuccess){
      handleClose()
    }
  },[isUpdateSuccess])
  useEffect(() => {
    if(isDeleteSuccess){
      handleClose()
    }
  },[isDeleteSuccess])
  useEffect( () => {
    if(user.id>1){
      setFormData((old) => { return {...old, institution_id: user.institution_id}})
    }
  }, [user]);

  return (
    <GenericTemplate title="ユーザ管理" is_top={true}>
      <Grid item xs={12}>
        <Paper sx={{p: 3}}>
          <Button variant='contained' sx={{mb:3}} onClick={() => handleClickOpen()}>新規</Button>
          <DataGrid
            autoHeight
            components={{ Toolbar: GridToolbar }}
            rows={rows}
            columns={columns}
            onRowClick={(params) => handleClickOpen(params.row.id)}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          />
        </Paper>
      </Grid>

      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>ユーザ情報</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
          <FormLabel htmlFor="institution_id">所属</FormLabel>
            <Select
              id="institution_id"
              value={formData.institution_id??''}
              name="institution_id"
              onChange={handleChange}
              disabled={formData.id===1}
            >
              <MenuItem disabled value={0}>所属を選択してください</MenuItem>
              {
                institutions_status === 'success' &&
                institutions.length>0 &&
                institutions.map( institution => <MenuItem value={institution.id}>{institution.name}</MenuItem>)
              }
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mt:2, mb:2 }}>
            <FormLabel htmlFor="loginid">ログインID</FormLabel>
            <TextField
              id="loginid"
              required
              value={formData.loginid??''}
              name="loginid"
              onChange={handleChange}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mt:2, mb:2 }}>
            <FormLabel htmlFor="password">パスワード</FormLabel>
            <TextField
              id="password"
              required
              value={formData.password??''}
              name="password"
              onChange={handleChange}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mt:2, mb:2 }}>
            <FormLabel htmlFor="name">名前</FormLabel>
            <TextField
              id="name"
              required
              value={formData.name??''}
              name="name"
              onChange={handleChange}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mt:2, mb:2 }}>
            <FormLabel htmlFor="role">表示役職名</FormLabel>
            <TextField
              id="role"
              required
              value={formData.role??''}
              name="role"
              onChange={handleChange}
            />
          </FormControl>
          <FormGroup>
            <FormControlLabel control={<Checkbox
              disabled={formData.id===1}
              name="admin"
              checked={formData.admin??''}
              onChange={handleChangeChkbox}
            />} label="管理者" />
          </FormGroup>
          <FormGroup>
            <FormControlLabel control={<Checkbox
              disabled={formData.id===1}
              name="active"
              checked={formData.active??''}
              onChange={handleChangeChkbox}
            />} label="有効" />
          </FormGroup>
          {formData.id!=='' && formData.id!==1 && false/* 一旦非表示 */ && <>
            <FormGroup>
              <FormControlLabel control={<Checkbox
                checked={checked}
                onChange={handleDelChange}
              />} label="このユーザを削除する" />
            </FormGroup>
            <Collapse in={checked}>
              <Button variant="contained" color="error" onClick={handleDelete}>削除（この操作はキャンセルできません）</Button> 
            </Collapse>
          </>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button variant="contained" onClick={handleSave} disabled={checked}>保存</Button>
        </DialogActions>
      </Dialog>

    </GenericTemplate>
  )
}

export default Admin