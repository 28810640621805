import axios from "axios";
import { config } from '../lib/config'

axios.defaults.withCredentials = true

const getUrgencies = async (id) => {
  const { data } = await axios.get(`${config.api_url}/patients/${id}/urgency`)
  return data
}

const getUrgency = async (id) => {
  const { data } = await axios.get(`${config.api_url}/urgency/${id}`)
  return data
}

const createUrgency = async (formdata) => {
  const { data } = await axios.post(
    `${config.api_url}/patients/${formdata.patient_id}/urgency`,
    formdata
  )
  return data
}

const updateUrgency = async (formdata) => {
  const { data } = await axios.patch(
    `${config.api_url}/urgency/${formdata.id}`,
    formdata
  )
  return data
}

export {
  getUrgencies,
  getUrgency,
  createUrgency,
  updateUrgency,
}