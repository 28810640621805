import * as api from "../api/InstitutionApi"
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'

const useInstitutions = () => {
  return useQuery('institutions', () => api.getInstitutions())
}

const useInstitution = (id) => {
  return useQuery(
    ['institution', id],
    () => api.getInstitution(id),
    { enabled: id > 0 }
  )
}

const useCreateInstitution = (formdata) => {
  const queryClient = useQueryClient()
  return useMutation(api.createInstitution, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('institutions')
      toast.success('登録が成功しました')
    },
    onError: (error) => {
      // console.log('error:',error)
      toast.error('登録に失敗しました')
    },
  })
}

const useUpdateInstitution = (id) => {
  const queryClient = useQueryClient()
  return useMutation(api.updateInstitution, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('institutions')
      queryClient.invalidateQueries('institution')
      toast.success('更新が成功しました')
    },
    onError: (error) => {
      toast.error('更新に失敗しました')
    },
  })
}

const useDeleteInstitution = (id) => {
  const queryClient = useQueryClient()
  return useMutation(api.deleteInstitution, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('institutions')
      toast.success('削除が成功しました')
    },
    onError: (error) => {
      toast.error('削除に失敗しました')
    },
  })
}
export {
  useInstitutions,
  useInstitution,
  useCreateInstitution,
  useUpdateInstitution,
  useDeleteInstitution
}