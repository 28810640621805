import { atom } from "recoil";

export const userState = atom({
  key: "userState",
  default: {
    isSignedIn: false,
    id: 0,
    name: '',
    institution_id: 0,
    institution: {
      name: '',
    },
    role: '',
  }
});

export const patientState = atom({
  key: "patientState",
  default: {
    patient_id: '',
  }
});
