import { dataset } from './dataset'

/*
* 年齢から評価項目のセット名を返す(PPATS:GCS)
*/
export const divideByAge = (age) => {
  if(age>=12){
    return 'set_c'
  }
  else if(age>0){
    return 'set_b'
  }
  else{
    return 'set_a'
  }
}

/*
* テープ色を判定する
*/
export const detectTapeColor = (height) => {
  //身長のみで判定する
  if(height<59)
    return 'gray'; // 灰
  else if(height<67)
    return 'pink'; // 桃
  else if(height<74)
    return 'red'; // 赤
  else if(height<84)
    return 'purple'; // 紫
  else if(height<98)
    return 'yellow'; // 黃
  else if(height<106)
    return 'white'; // 白
  else if(height<122)
    return 'blue'; // 青
  else if(height<131)
    return 'orange'; // 橙
  else
    return 'green'; // 緑
}

/*
* PPATSの判定区分を返す
*/
export const detectDivision = (height) => {
  const data = dataset.division.find(obj => {
    const _con = obj.height.map(c => 'height' + c)
    if(eval(_con.join('&&'))){
      // console.log(_con.join('&&'), obj.division)
      return obj.division
    }
  })
  return data ? data.division : ''
}

/*
* START判定する
*/
export const detectSTART = (props) => {
  const {
    is_walk,
    spontaneous_breathing,
    manual_airway_control,
    respiratory_rate,
    pulse,
    obedience_reaction,
    other
  } = props

  if(is_walk===1){
    console.log('a')
    return 'green'
  }
  else if(spontaneous_breathing===0){
    if(manual_airway_control===1){
      console.log('b')
      return 'red'
    }
    else if(manual_airway_control===0){
      console.log('c')
      return 'black'
    }
  }
  else if(respiratory_rate!=='' && respiratory_rate!==null && (respiratory_rate<10 || respiratory_rate>29)){
    console.log('d')
    return 'red'
  }
  else if(pulse!=='' && (pulse>=120 || pulse===-1)){
    console.log('e')
    return 'red'
  }
  else if(obedience_reaction===1){
    console.log('f')
    return 'yellow'
  }
  else if(obedience_reaction===0){
    console.log('g')
    return 'red'
  }
}


/*
* PPATS判定する
*/
export const detectPPATS = (props) => {
  const {
    division, // 判定の区分(0-23)
    respiratory_rate,
    heart_rate,
    sbp,
    gcs,
    is_findings_of_anatomical_abnormal_id,
    is_medical_intervention_id,
    other
  } = props
  let score = 0

  if(division<0 || division >23 || respiratory_rate==='' || heart_rate==='' || sbp===''){
    return ''
  }

  // 呼吸数
  dataset.ppats_respiratory_rate.find(element => element.division === division).range.map(obj => {
    const _con = obj.conditions.map(c => 'respiratory_rate' + c)
    if(eval(_con.join('&&'))){
      score += obj.point
      console.log('ppats_respiratory_rate',obj.point)
    }
  })

  // 心拍数
  dataset.ppats_heart_rate.find(element => element.division === division).range.map(obj => {
    const _con = obj.conditions.map(c => 'heart_rate' + c)
    if(eval(_con.join('&&'))) {
      score += obj.point
      console.log('ppats_heart_rate',obj.point)
    }
  })

  // 収縮期血圧
  if(eval('sbp' + dataset.ppats_sbp.find(element => element.division ===division).range)) {
    score += 4
  }

  // GCS
  if(gcs >= 15) { score += 0 }
  else if(gcs >= 13) { score += 1 }
  else if(gcs >= 9) { score += 2 }
  else { score += 4 }

  // 解剖学的異常所見
  if(is_findings_of_anatomical_abnormal_id.length > 0) {
    score += 4
  }

  // 医療的処置
  if(is_medical_intervention_id.length > 0) {
    score += 4
  }

  // console.log('score',score)
  return score
}

export const detectPPATSColor = (score) => {
  if(score === '') return ''
  // else if(score > 23) return 'black'
  else if(score > 6) return 'red'
  else if(score > 0) return 'yellow'
  else return 'green'
}

/*
* 緊急度判定する
*/
export const detectUrgency = (props) => {
  const {
    division, // 判定の区分(0-23)
    respiratory_rate,
    heart_rate,
    sbp,
    gcs,
    other
  } = props
  let score = 0

  if(division<0 || division >23 || respiratory_rate==='' || heart_rate==='' || sbp===''){
    return ''
  }

  // 呼吸数
  dataset.ppats_respiratory_rate.find(element => element.division === division).range.map(obj => {
    const _con = obj.conditions.map(c => 'respiratory_rate' + c)
    if(eval(_con.join('&&'))){
      score += obj.point
      console.log('ppats_respiratory_rate',obj.point)
    }
  })

  // 心拍数
  dataset.ppats_heart_rate.find(element => element.division === division).range.map(obj => {
    const _con = obj.conditions.map(c => 'heart_rate' + c)
    if(eval(_con.join('&&'))) {
      score += obj.point
      console.log('ppats_heart_rate',obj.point)
    }
  })

  // 収縮期血圧
  if(eval('sbp' + dataset.ppats_sbp.find(element => element.division ===division).range)) {
    score += 4
  }

  // GCS
  if(gcs >= 15) { score += 0 }
  else if(gcs >= 13) { score += 1 }
  else if(gcs >= 9) { score += 2 }
  else { score += 4 }

  return score
}

export const detectUrgencyColor = (score) => {
  if(score === '') return ''
  else if(score > 6) return 'red'
  else if(score > 0) return 'yellow'
  else return 'green'
}