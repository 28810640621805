import React, { useState, useEffect } from "react";
import { useNavigate, matchPath, useLocation, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Paper, Stack,
  FormControl, InputLabel, Input,
  Button, ButtonGroup,
  Tabs, Tab
} from '@mui/material'
import { usePatient, usePatientState } from '../../queries/PatientQuery';
import { useRecoilState } from 'recoil';
import { patientState } from "../../recoil/atom";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  padding: '0 20px',
  height: 60,
  display: 'flex',
  alignItems: 'center',
  // lineHeight: '60px',
  '&.black': { backgroundColor: 'black', color: 'white'},
  '&.red': { backgroundColor: 'red', color: 'white'},
  '&.yellow': { backgroundColor: 'yellow'},
  '&.green': { backgroundColor: 'green', color: 'white'},
}));

const useRouteMatch = (patterns) => {
  const {pathname} = useLocation()
  for (let i = 0; i < patterns.length; i++){
    const pattern = patterns[i]
    const possibleMatch = matchPath(pattern, pathname)
    if(possibleMatch !== null){
      return possibleMatch
    }
  }
  return null
}

const Menu = () => {
  const params = useParams()
  const { data, status } = usePatient(params.id)
  const { data: state_data, status: state_status } = usePatientState(params.id)
  const [state, setState] = useState({
    start: '',
    ppats: '',
    urgency: '',
  })
  const navigate = useNavigate()
  const routeMatch = useRouteMatch([
    '/:id/start',
    '/:id/ppats',
    '/:id/urgency',
    '/:id/record',
    '/:id/list',
    '/:id',
  ]);
  const currentTab = routeMatch?.pattern?.path;
  const [patient, setPatient] =  useRecoilState(patientState);

  useEffect(() => {
    if(status === 'success'){
      console.log('patient', data)
      setPatient(data)
    }
  },[status])
  useEffect(() => {
    if(state_status === 'success'){
      console.log(state_data)
      setState({
        start: state_data.start?state_data.start.triage_priority:'',
        ppats: state_data.ppats?state_data.ppats.triage_priority:'',
        urgency: state_data.urgency?state_data.urgency.triage_priority:'',
      })
    }
  },[state_status])

  return (
    <>
      <Stack direction="row" spacing={1}>
        <FormControl variant="standard" fullWidth disabled sx={{mb: 1}}>
          <InputLabel htmlFor="component-simple">患者ID</InputLabel>
          <Input id="component-simple" value={patient?patient.patient_id:''} />
        </FormControl>
        <Item variant="outlined" className={state.urgency}>緊急度判定</Item>
        <Item variant="outlined" className={state.start}>START</Item>
        <Item variant="outlined" className={state.ppats}>PPATS</Item>
      </Stack>
      <Tabs value={currentTab}>
        <Tab label="患者基本情報" value="/:id" onClick={() => navigate(`/${params.id}`)} />
        <Tab label="緊急度判定" value="/:id/urgency" onClick={() => navigate(`/${params.id}/urgency`)} />
        <Tab label="START" value="/:id/start" onClick={() => navigate(`/${params.id}/start`)} />
        <Tab label="PPATS" value="/:id/ppats" onClick={() => navigate(`/${params.id}/ppats`)} />
        <Tab label="診療録" value="/:id/record" onClick={() => navigate(`/${params.id}/record`)} />
        <Tab label="判定結果一覧" value="/:id/list" onClick={() => navigate(`/${params.id}/list`)} />
      </Tabs>
    </>
  )
}

export default Menu