import React, {useState, useEffect} from "react"
import { useNavigate, useParams } from 'react-router-dom';
import GenericTemplate from '../templates/GenericTemplate'
import Timer from '../templates/Timer';
import CustomCheckbox from '../templates/CustomCheckbox';
import { dataset } from '../../lib/dataset'
import { divideByAge } from '../../lib/functions'
import { useSelectOptions, useMedicalRecord, useCreateMedicalRecord, useUpdateMedicalRecord } from '../../queries/MedicalRecordQuery';
import {
  Card, CardContent, CardActions,
  Paper, Grid, Stack, Divider,
  TextField, InputAdornment,
  Typography, Collapse,
  FormControl, FormLabel, FormGroup, FormControlLabel, RadioGroup, Radio, Checkbox, Button, Select, MenuItem
} from '@mui/material'
import { useRecoilValue } from 'recoil';
import { patientState } from "../../recoil/atom";
import {format, parseISO} from 'date-fns'

const MedicalRecord = () => {
  const params = useParams()
  const navigate = useNavigate();
  const default_form_data = {
    id: '',
    patient_id: params.id,
    user_id: 1,
    institution: '',
    attending_physician: '',
    address: '',
    phone: '',
    respiratory_rate: '',
    spo2: '',
    heart_rate: '',
    sbp: '',
    dbp: '',
    urine_volume: '',
    gcs_e: 0,
    gcs_v: 0,
    gcs_m: 0,
    gcs:0,
    temperature: '',
    is_findings_of_anatomical_abnormal_id: [],
    test: [],
    treatment: [],
    treatment_sub: {},
    additional_treatment: '',
    integrated_amount: '',
    time_infusion_volume: '',
    integrated_amount: '',
    diagnosis: '',
    diagnosis_name: '',
    outcome: [],
    outcome_sub: {},
    is_survival: '',
    updated_at: '',
  }
  const [formData, setFormData] = useState(default_form_data)
  const [respiratoryRate, setRespiratoryRate] = useState('')
  const [heartRate, setHeartRate] = useState('')
  const [subTreat, setSubTreat] = useState({})
  const [subOutcome, setSubOutcome] = useState({})
  const { data: select_options, status: options_status } = useSelectOptions()
  const patient =  useRecoilValue(patientState);
  const { data : current, status : current_status } = useMedicalRecord(params.id)
  const { isSuccess, mutate } = useCreateMedicalRecord()
  const { isSuccess: isUpdateSuccess, mutate: updateMutate } = useUpdateMedicalRecord()

  const handleChange = (event) => {
    if(
        event.target.name==='is_findings_of_anatomical_abnormal_id' ||
        event.target.name==='test' ||
        event.target.name==='treatment' ||
        event.target.name==='outcome'
      ){
      setFormData((old) => {
        if(old[event.target.name].includes(parseInt(event.target.value))){
          return {...old, [event.target.name]: old[event.target.name].filter(checked => checked !== parseInt(event.target.value))}
        }
        else{
          return {...old, [event.target.name]: [...old[event.target.name], parseInt(event.target.value)]}
        }
      })
    }
    else{
      setFormData((old) => { return {...old, [event.target.name]: event.target.value}})
      if(event.target.name==='gcs_e'){
        setFormData((old) => { return {...old, gcs: old.gcs_v + old.gcs_m + event.target.value}})
      }
      if(event.target.name==='gcs_v'){
        setFormData((old) => { return {...old, gcs: old.gcs_e + old.gcs_m + event.target.value}})
      }
      if(event.target.name==='gcs_m'){
        setFormData((old) => { return {...old, gcs: old.gcs_e + old.gcs_v + event.target.value}})
      }
    }
  }
  const handleChangeSub = (event, name, id, sub_id, type) => {
    // console.log('handle change sub', event.target.value, name, id, sub_id, formData)
    setFormData((old) => {
      return {...old, [name]: {...old[name], [`${id}-${sub_id}`]: {[type]:event.target.value}}}
    })
    if(name==='treatment_sub'){
      setSubTreat((old) => {
        return {...old, [`${id}-${sub_id}`]: event.target.value}
      })
    }
    if(name==='outcome_sub'){
      console.log('outcome_sub value')
      setSubOutcome((old) => {
        return {...old, [`${id}-${sub_id}`]: event.target.value}
      })
    }
  }
  const handleChangeSubBoolean = (event, name, id, sub_id, type) => {
    setFormData((old) => {
      return {...old, [name]: {...old[name], [`${id}-${sub_id}`]: {[type]:event.target.checked}}}
    })
    if(name==='treatment_sub'){
      setSubTreat((old) => {
        return {...old, [`${id}-${sub_id}`]: event.target.checked}
      })
    }
    if(name==='outcome_sub'){
      console.log('outcome_sub checked')
      setSubOutcome((old) => {
        return {...old, [`${id}-${sub_id}`]: event.target.checked}
      })
    }
  }
  // DBへ登録・更新
  const handleSave = () => {
    // console.log(formData)
    if(formData.id===''){
      mutate({ ...formData })
    }
    else{
      updateMutate({ ...formData })
    }
  }

  useEffect(() => {
    if(current_status === 'success'){
      console.log('load data', current)
      setFormData((old) => { return {...old, ...current}})
      Object.keys(current.treatment_sub).map(item => {
        setSubTreat((old) => { return {...old, [item]: Object.keys(current.treatment_sub[item]).map(key => {
          if(key==='value_boolean') return current.treatment_sub[item][key]===1
          else return current.treatment_sub[item][key]
        })[0]}})
      })
      Object.keys(current.outcome_sub).map(item => {
        setSubOutcome((old) => { return {...old, [item]: Object.keys(current.outcome_sub[item]).map(key => {
          if(key==='value_boolean') return current.outcome_sub[item][key]===1
          else return current.outcome_sub[item][key]
        })[0]}})
      })
    }
  },[current_status])
  useEffect(() => {
    setFormData((old) => { return {...old, respiratory_rate: respiratoryRate}})
  },[respiratoryRate])
  useEffect(() => {
    setFormData((old) => { return {...old, heart_rate: heartRate}})
  },[heartRate])
  useEffect(() => {
    console.log('select_options', select_options)
  },[select_options])

  return (
    <GenericTemplate title="診療録">
      <Grid item xs={12}>
        <Card sx={{p: 3}}>
          <CardContent>
            <Typography variant="body1" align="right">更新日：{formData.updated_at!==''?format(parseISO(formData.updated_at), "yyyy/MM/dd HH:mm"):'----/--/-- --:--'}</Typography>
            <Typography variant="h6">収容先医療機関情報</Typography>
            <Grid container spacing={2} sx={{ mb:2 }}>
              <Grid item xs={6}>
                <FormControl fullWidth sx={{ mt:2, mb:2 }}>
                  <FormLabel htmlFor="institution-input">施設名</FormLabel>
                  <TextField
                    id="institution-input"
                    required
                    name="institution"
                    value={formData.institution??''}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth sx={{ mt:2, mb:2 }}>
                  <FormLabel htmlFor="attending_physician-input">主治医名</FormLabel>
                  <TextField
                    id="attending_physician-input"
                    required
                    name="attending_physician"
                    value={formData.attending_physician??''}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth sx={{ mb:2 }}>
                  <FormLabel htmlFor="address-input">住所</FormLabel>
                  <TextField
                    id="address-input"
                    required
                    name="address"
                    value={formData.address??''}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth sx={{ mb:2 }}>
                  <FormLabel htmlFor="phone-input">電話番号</FormLabel>
                  <TextField
                    id="phone-input"
                    required
                    name="phone"
                    value={formData.phone??''}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Divider />
            <Typography variant="h6" sx={{ mt:2 }}>生理学的特徴</Typography>
            <Stack direction="column" spacing={3} sx={{ mt:2, mb:2 }}>
              <FormControl>
                <FormLabel id="level3-group-label">呼吸数</FormLabel>
                <Grid container direction="row" alignItems="center" spacing={1}>
                  <Grid item>
                    <TextField
                      required
                      InputProps={{
                        endAdornment: <InputAdornment position="start">回／分</InputAdornment>,
                      }}
                      value={formData.respiratory_rate??''}
                      name="respiratory_rate"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item>
                    <Timer name="呼吸数" times="5" setValue={setRespiratoryRate} />
                  </Grid>
                </Grid>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="spo2-input">経皮的酸素飽和度</FormLabel>
                <Grid container>
                  <Grid item>
                    <TextField
                      id="spo2-input"
                      required
                      InputProps={{
                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                      }}
                      name="spo2"
                      value={formData.spo2??''}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </FormControl>
              <FormControl>
                <FormLabel id="level4-group-label">心拍数</FormLabel>
                <Grid container direction="row" alignItems="center" spacing={1}>
                  <Grid item>
                    <TextField
                      required
                      InputProps={{
                        endAdornment: <InputAdornment position="start">回／分</InputAdornment>,
                      }}
                      value={formData.heart_rate??''}
                      name="heart_rate"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item>
                  <Timer name="心拍数" times="5" setValue={setHeartRate} />
                  </Grid>
                </Grid>
              </FormControl>
            </Stack>
            <Grid container sx={{ mt:4 }}>
              <Grid item xs={6}>
                <FormControl sx={{ mb:4 }}>
                  <FormLabel htmlFor="sbp-input">収縮期血圧</FormLabel>
                  <TextField
                    id="sbp-input"
                    required
                    InputProps={{
                      endAdornment: <InputAdornment position="start">mmHg</InputAdornment>,
                    }}
                    name="sbp"
                    value={formData.sbp??''}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl sx={{ mb:4 }}>
                  <FormLabel htmlFor="dbp-input">拡張期血圧</FormLabel>
                  <TextField
                    id="dbp-input"
                    required
                    InputProps={{
                      endAdornment: <InputAdornment position="start">mmHg</InputAdornment>,
                    }}
                    name="dbp"
                    value={formData.dbp??''}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl sx={{ mb:4 }}>
                  <FormLabel htmlFor="urine_volume-input">時間尿量（ml/kg/時）</FormLabel>
                  <TextField
                    id="urine_volume-input"
                    required
                    InputProps={{
                      endAdornment: <InputAdornment position="start">ml</InputAdornment>,
                    }}
                    name="urine_volume"
                    value={formData.urine_volume??''}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mb:3 }}>
              <FormControl>
                <FormLabel id="level4-group-label" sx={{ mb:1 }}>Glasgow coma scale</FormLabel>
                <Typography>合計値：{formData.gcs===0 ? '' : formData.gcs}</Typography>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mb:3 }}>
              <FormControl fullWidth>
                <FormLabel id="demo-simple-select-label">E. 開眼</FormLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.gcs_e}
                  name="gcs_e"
                  onChange={handleChange}
                >
                  <MenuItem disabled value="0"><em>選択してください</em></MenuItem>
                  {dataset.gcs.select_e[divideByAge(patient.age)].length && dataset.gcs.select_e[divideByAge(patient.age)].map((item,index)=>{
                    return (
                    <MenuItem value={item.value} key={index}>{item.text}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mb:3 }}>
              <FormControl fullWidth>
                <FormLabel id="demo-simple-select-label">V. 言語音声反応</FormLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.gcs_v}
                  name="gcs_v"
                  onChange={handleChange}
                >
                  <MenuItem disabled value="0"><em>選択してください</em></MenuItem>
                  {dataset.gcs.select_e[divideByAge(patient.age)].length && dataset.gcs.select_v[divideByAge(patient.age)].map((item,index)=>{
                    return (
                    <MenuItem value={item.value} key={index}>{item.text}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mb:4 }}>
              <FormControl fullWidth>
                <FormLabel id="demo-simple-select-label">M. 最良の運動反応</FormLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.gcs_m}
                  name="gcs_m"
                  onChange={handleChange}
                >
                  <MenuItem disabled value="0"><em>選択してください</em></MenuItem>
                  {dataset.gcs.select_e[divideByAge(patient.age)].length && dataset.gcs.select_m[divideByAge(patient.age)].map((item,index)=>{
                    return (
                    <MenuItem value={item.value} key={index}>{item.text}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ mb:4 }}>
              <FormControl>
                <FormLabel htmlFor="temperature-input">体温</FormLabel>
                <TextField
                  id="temperature-input"
                  required
                  InputProps={{
                    endAdornment: <InputAdornment position="start">℃</InputAdornment>,
                  }}
                  name="temperature"
                  value={formData.temperature??''}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mb:4 }}>
              <FormControl>
                <FormLabel id="level4-group-label">解剖学的異常所見の有無</FormLabel>
                <FormGroup row>
                  {options_status==='success' && select_options.foaa.length>0 && select_options.foaa.map(item => {
                    return (
                      <FormControlLabel
                        key={item.id}
                        label={item.item}
                        control={
                          <CustomCheckbox
                            name="is_findings_of_anatomical_abnormal_id"
                            value={item.id}
                            onChange={handleChange}
                            checked={formData.is_findings_of_anatomical_abnormal_id.includes(item.id)}
                          />
                        }
                      />
                    )
                  })}
                </FormGroup>
              </FormControl>
            </Grid>
            <Divider />
            <Typography variant="h6" sx={{ mt:2 }}>検査</Typography>
            <Grid item xs={12} sx={{ mb:4 }}>
              <FormControl>
                <FormGroup>
                  {options_status==='success' && select_options.test.length>0 && select_options.test.map(item => {
                    return (
                      <FormControlLabel
                        key={item.id}
                        label={item.item}
                        control={
                          <CustomCheckbox
                            name="test"
                            value={item.id}
                            onChange={handleChange}
                            checked={formData.test.includes(item.id)}
                          />
                        }
                      />
                    )
                  })}
                </FormGroup>
              </FormControl>
            </Grid>
            <Divider />
            <Typography variant="h6" sx={{ mt:2 }}>処置</Typography>
            <Grid item xs={12} sx={{ mb:2 }}>
              <FormControl fullWidth>
                <FormGroup>
                  {options_status==='success' && select_options.treatment.length>0 && select_options.treatment.map(item => {
                    return (
                      <React.Fragment key={`treatment-${item.id}`}>
                        <FormControlLabel
                          label={item.item}
                          control={
                            <CustomCheckbox
                              name="treatment"
                              value={item.id}
                              onChange={handleChange}
                              checked={formData.treatment.includes(item.id)}
                            />
                          }
                        />
                        {item.has_sub===1 && <Collapse in={formData.treatment.includes(item.id)}>
                          <Grid container spacing={2}>
                            {item.sub.map(sub_item => {
                              return (
                                <Grid item xs={6} key={`treatment-sub-${sub_item.id}`}>
                                  {sub_item.type==='string' && <FormControl fullWidth sx={{ mb:2 }}>
                                    <FormLabel htmlFor={`treatment-${item.id}-${sub_item.id}-input`}>{sub_item.name}</FormLabel>
                                    <TextField
                                      id={`treatment-${item.id}-${sub_item.id}-input`}
                                      required
                                      name={`treatment-${item.id}-${sub_item.id}`}
                                      value={subTreat[`${item.id}-${sub_item.id}`]??''}
                                      onChange={(e) => handleChangeSub(e, 'treatment_sub', item.id, sub_item.id, 'value_string')}
                                    />
                                  </FormControl>}
                                  {sub_item.type==='text' && <FormControl fullWidth sx={{ mb:2 }}>
                                    <FormLabel htmlFor={`treatment-${item.id}-${sub_item.id}-input`}>{sub_item.name}</FormLabel>
                                    <TextField
                                      id={`treatment-${item.id}-${sub_item.id}-input`}
                                      required
                                      name={`treatment-${item.id}-${sub_item.id}`}
                                      value={subTreat[`${item.id}-${sub_item.id}`]??''}
                                      onChange={(e) => handleChangeSub(e, 'treatment_sub', item.id, sub_item.id, 'value_text')}
                                      multiline
                                      rows={4}
                                    />
                                  </FormControl>}
                                  {sub_item.type==='integer' && <FormControl sx={{ mb:2 }}>
                                    <FormLabel htmlFor={`treatment-${item.id}-${sub_item.id}-input`}>{sub_item.name}</FormLabel>
                                    <TextField
                                      id={`treatment-${item.id}-${sub_item.id}-input`}
                                      required
                                      InputProps={sub_item.unit!=''?{
                                        endAdornment: <InputAdornment position="start">{sub_item.unit}</InputAdornment>,
                                      }:null}
                                      name={`treatment-${item.id}-${sub_item.id}`}
                                      value={subTreat[`${item.id}-${sub_item.id}`]??''}
                                      onChange={(e) => handleChangeSub(e, 'treatment_sub', item.id, sub_item.id, 'value_integer')}
                                    />
                                  </FormControl>}
                                  {sub_item.type==='boolean' && <FormControlLabel
                                    control={<Checkbox
                                      checked={subTreat[`${item.id}-${sub_item.id}`]??false}
                                      onChange={(e) => handleChangeSubBoolean(e, 'treatment_sub', item.id, sub_item.id, 'value_boolean')}
                                    />}
                                    label={sub_item.name}
                                  />}
                                </Grid>
                              )
                            })}
                          </Grid>
                        </Collapse>}
                      </React.Fragment>
                    )
                  })}
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mb:4 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="additional_treatment-input">その他の処置あり</FormLabel>
                <TextField
                  id="additional_treatment-input"
                  required
                  name="additional_treatment"
                  value={formData.additional_treatment??''}
                  onChange={handleChange}
                  multiline
                  rows={4}
                />
              </FormControl>
            </Grid>
            <Divider />
            <Typography variant="h6" sx={{ mt:2, mb:2 }}>輸液投与量</Typography>
            <Grid container>
              <Grid item xs={6} sx={{ mb:4 }}>
                <FormControl>
                  <FormLabel htmlFor="time_infusion_volume-input">時間輸液量</FormLabel>
                  <TextField
                    id="time_infusion_volume-input"
                    InputProps={{
                      endAdornment: <InputAdornment position="start">ml</InputAdornment>,
                    }}
                    name="time_infusion_volume"
                    value={formData.time_infusion_volume??''}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sx={{ mb:4 }}>
                <FormControl>
                  <FormLabel htmlFor="integrated_amount-input">積算量</FormLabel>
                  <TextField
                    id="integrated_amount-input"
                    InputProps={{
                      endAdornment: <InputAdornment position="start">ml</InputAdornment>,
                    }}
                    name="integrated_amount"
                    value={formData.integrated_amount??''}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mb:4 }}>
              <FormControl>
                <FormLabel htmlFor="diagnosis-input">診断</FormLabel>
                <RadioGroup
                  id="diagnosis-input"
                  name="diagnosis"
                  value={formData.diagnosis??''}
                  onChange={handleChange}
                >
                  <FormControlLabel value="内因" control={<Radio />} label="内因" />
                  <FormControlLabel value="外因" control={<Radio />} label="外因" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mb:4 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="diagnosis_name-input">診断名</FormLabel>
                <TextField
                  id="diagnosis_name-input"
                  name="diagnosis_name"
                  value={formData.diagnosis_name??''}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Divider />
            <Typography variant="h6" sx={{ mt:2, mb:2 }}>転帰</Typography>
            <Grid item xs={12} sx={{ mb:2 }}>
              <FormControl fullWidth>
                <FormGroup>
                  {options_status==='success' && select_options.outcome.length>0 && select_options.outcome.map(item => {
                    return (
                      <React.Fragment key={`outcome-${item.id}`}>
                        <FormControlLabel
                          label={item.item}
                          control={
                            <CustomCheckbox
                              name="outcome"
                              value={item.id}
                              onChange={handleChange}
                              checked={formData.outcome.includes(item.id)}
                            />
                          }
                        />
                        {item.has_sub===1 && <Collapse in={formData.outcome.includes(item.id)}>
                          <Grid container spacing={2}>
                            {item.sub.map(sub_item => {
                              return (
                                <Grid item xs={6} key={`outcome-sub-${sub_item.id}`}>
                                  {sub_item.type==='string' && <FormControl fullWidth sx={{ mb:2 }}>
                                    <FormLabel htmlFor={`outcome-${item.id}-${sub_item.id}-input`}>{sub_item.name}</FormLabel>
                                    <TextField
                                      id={`outcome-${item.id}-${sub_item.id}-input`}
                                      required
                                      name={`outcome-${item.id}-${sub_item.id}`}
                                      value={subOutcome[`${item.id}-${sub_item.id}`]??''}
                                      onChange={(e) => handleChangeSub(e, 'outcome_sub', item.id, sub_item.id, 'value_string')}
                                    />
                                  </FormControl>}
                                  {sub_item.type==='text' && <FormControl fullWidth sx={{ mb:2 }}>
                                    <FormLabel htmlFor={`outcome-${item.id}-${sub_item.id}-input`}>{sub_item.name}</FormLabel>
                                    <TextField
                                      id={`outcome-${item.id}-${sub_item.id}-input`}
                                      required
                                      name={`outcome-${item.id}-${sub_item.id}`}
                                      value={subOutcome[`${item.id}-${sub_item.id}`]??''}
                                      onChange={(e) => handleChangeSub(e, 'outcome_sub', item.id, sub_item.id, 'value_text')}
                                      multiline
                                      rows={4}
                                    />
                                  </FormControl>}
                                  {sub_item.type==='integer' && <FormControl sx={{ mb:2 }}>
                                    <FormLabel htmlFor={`outcome-${item.id}-${sub_item.id}-input`}>{sub_item.name}</FormLabel>
                                    <TextField
                                      id={`outcome-${item.id}-${sub_item.id}-input`}
                                      required
                                      InputProps={sub_item.unit!=''?{
                                        endAdornment: <InputAdornment position="start">{sub_item.unit}</InputAdornment>,
                                      }:null}
                                      name={`outcome-${item.id}-${sub_item.id}`}
                                      value={subOutcome[`${item.id}-${sub_item.id}`]??''}
                                      onChange={(e) => handleChangeSub(e, 'outcome_sub', item.id, sub_item.id, 'value_integer')}
                                    />
                                  </FormControl>}
                                  {sub_item.type==='boolean' && <FormControlLabel
                                    control={<Checkbox
                                      checked={subOutcome[`${item.id}-${sub_item.id}`]??false}
                                      onChange={(e) => handleChangeSubBoolean(e, 'outcome_sub', item.id, sub_item.id, 'value_boolean')}
                                    />}
                                    label={sub_item.name}
                                  />}
                                </Grid>
                              )
                            })}
                          </Grid>
                        </Collapse>}
                      </React.Fragment>
                    )
                  })}
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mb:4 }}>
              <FormControl>
                <FormLabel htmlFor="is_survival-input">生存・死亡</FormLabel>
                <RadioGroup
                  id="is_survival-input"
                  name="is_survival"
                  value={formData.is_survival??''}
                  onChange={handleChange}
                >
                  <FormControlLabel value="生存" control={<Radio />} label="生存" />
                  <FormControlLabel value="死亡" control={<Radio />} label="死亡" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </CardContent>
          <CardActions>
            <Button variant="outlined" onClick={()=>{navigate(`/${params.id}/record`)}}>キャンセル</Button>
            <Button variant="contained" onClick={handleSave}>保存</Button>
          </CardActions>
        </Card>
      </Grid>
    </GenericTemplate>
  )
}

export default MedicalRecord