import axios from "axios";
import { config } from '../lib/config'

axios.defaults.withCredentials = true

const getStarts = async (id) => {
  const { data } = await axios.get(`${config.api_url}/patients/${id}/start`)
  return data
}

const getStart = async (id) => {
  const { data } = await axios.get(`${config.api_url}/start/${id}`)
  return data
}

const createStart = async (formdata, triage_priority) => {
  const { data } = await axios.post(
    `${config.api_url}/patients/${formdata.patient_id}/start`,
    formdata
  )
  return data
}

const updateStart = async (formdata) => {
  const { data } = await axios.patch(
    `${config.api_url}/start/${formdata.id}`,
    formdata
  )
  return data
}

export {
  getStarts,
  getStart,
  createStart,
  updateStart,
}