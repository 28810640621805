import React, {useEffect} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Dashboard, SignIn, New, Detail, Start, Ppats, Urgency, MedicalRecord, List, Admin, Institution } from './components/pages'
import GuardRoute from "./components/templates/GuardRoute";
import LoginRoute from "./components/templates/LoginRoute";
import AdminRoute from "./components/templates/AdminRoute";
import RootRoute from "./components/templates/RootRoute";

import { useSetRecoilState } from 'recoil';
import { userState } from "./recoil/atom";

import {useUser} from './queries/AuthQuery'

const Router = () => {
  const setUser = useSetRecoilState(userState);
  const {data: authUser} = useUser()

  useEffect(() => {
    if(authUser){
      setUser({
        ...authUser,
        isSignedIn: true,
      });
    }
  },[authUser, setUser])

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LoginRoute />}>
          <Route path="/signin" element={<SignIn />} />
        </Route>
        <Route element={<AdminRoute />}>
          <Route path="/admin" element={<Admin />} />
        </Route>
        <Route element={<RootRoute />}>
          <Route path="/institution" element={<Institution />} />
        </Route>
        <Route element={<GuardRoute />}>
          <Route index element={<Dashboard />} />
          <Route path="/new" element={<New />} />
          <Route path="/:id" element={<Detail />} />
          <Route path="/:id/start" element={<Start />} />
          <Route path="/:id/ppats" element={<Ppats />} />
          <Route path="/:id/urgency" element={<Urgency />} />
          <Route path="/:id/record" element={<MedicalRecord />} />
          <Route path="/:id/list" element={<List />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Router