import React, {useState,useEffect} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import GenericTemplate from '../templates/GenericTemplate'
import Timer from '../templates/Timer';
import Triage from '../templates/Triage';
import CustomCheckbox from '../templates/CustomCheckbox';
import {
  Paper, Grid, Stack, Divider,
  Dialog, DialogTitle, DialogContent, DialogActions, IconButton, 
  Typography,
  FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, RadioGroup, Radio, TextField, Select, MenuItem,
  InputLabel, InputAdornment, Button,
} from '@mui/material'
import { DataGrid, GridToolbar, jaJP } from '@mui/x-data-grid';
import { dataset } from '../../lib/dataset'
import { divideByAge, detectPPATS, detectPPATSColor } from '../../lib/functions'
import { CheckBox as CheckBoxIcon } from '@mui/icons-material';
import { usePpatss, usePpats, useCreatePpats, useUpdatePpats, useSelectOptions } from '../../queries/PpatsQuery';
import {format, parseISO} from 'date-fns'
import { useRecoilValue } from 'recoil';
import { patientState, userState } from "../../recoil/atom";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Ppats = () => {
  const params = useParams()
  const [open, setOpen] = useState(false);
  const [triageColor, setTriageColor] = useState('')
  const user = useRecoilValue(userState)
  const columns = [
    {
      field: 'created_at',
      headerName: '実施日',
      width: 300,
      valueFormatter: (params) => {
        if(params.value == null) {
          return ''
        }
        else {
          return format(parseISO(params.value), "yyyy/MM/dd HH:mm")
        }
      }
    },
    {
      field: 'triage_priority',
      headerName: '判定結果',
      width: 200,
      renderCell: (params) => <Triage color={params.value} />
    },
    {
      field: 'ppats_score',
      headerName: '点数',
      width: 150,
    },
  ]
  const [rows, setRows] = useState([])
  const [currentId, setCurrentId] = useState(-1)
  const default_form_data = {
    id: '',
    patient_id: params.id,
    user_id: 0,
    respiratory_rate: '',
    heart_rate: '',
    sbp: '',
    gcs_e: 0,
    gcs_v: 0,
    gcs_m: 0,
    gcs:0,
    is_findings_of_anatomical_abnormal_id: [],
    is_medical_intervention_id: [],
    ppats_score: '',
    triage_priority: '',
  }
  const [respiratoryRate, setRespiratoryRate] = useState('')
  const [heartRate, setHeartRate] = useState('')
  const [score, setScore] = useState('')
  const [formData, setFormData] = useState(default_form_data)
  const { data, status } = usePpatss(params.id)
  const { data : current, status : current_status } = usePpats(currentId)
  const { data: select_options, status: options_status } = useSelectOptions()
  const { isSuccess, mutate } = useCreatePpats()
  const { isSuccess: isUpdateSuccess, mutate: updateMutate } = useUpdatePpats()
  const patient =  useRecoilValue(patientState);

  // 判定画面を開く
  const handleClickOpen = (id = '') => {
    if(id!==''){
      setCurrentId(id)
      // すでに取得済みの場合
      if(current_status === 'success'){
        setFormData({...current, gcs: current.gcs_e + current.gcs_v + current.gcs_m})
      }
    }
    setOpen(true);
  };
  // 判定画面を閉じる
  const handleClose = () => {
    setFormData(default_form_data)
    setCurrentId(-1)
    setOpen(false);
  };
  // 値の更新処理
  const handleChange = (event) => {
    if(event.target.name==='is_findings_of_anatomical_abnormal_id' ||
       event.target.name==='is_medical_intervention_id'
     ){
      setFormData((old) => {
        if(old[event.target.name].includes(parseInt(event.target.value))){
          return {...old, [event.target.name]: old[event.target.name].filter(checked => checked !== parseInt(event.target.value))}
        }
        else{
          return {...old, [event.target.name]: [...old[event.target.name], parseInt(event.target.value)]}
        }
      })
    }
    else {
      setFormData((old) => { return {...old, [event.target.name]: event.target.value}})
      // console.log(event.target,event.target.value, formData.gcs_e)
      if(event.target.name==='gcs_e'){
        setFormData((old) => { return {...old, gcs: old.gcs_v + old.gcs_m + event.target.value}})
      }
      if(event.target.name==='gcs_v'){
        setFormData((old) => { return {...old, gcs: old.gcs_e + old.gcs_m + event.target.value}})
      }
      if(event.target.name==='gcs_m'){
        setFormData((old) => { return {...old, gcs: old.gcs_e + old.gcs_v + event.target.value}})
      }
    }
  };
  // DBへ登録・更新
  const handleSave = () => {
    if(formData.id===''){
      mutate({ ...formData, triage_priority: triageColor, ppats_score: score })
    }
    else{
      updateMutate({ ...formData, triage_priority: triageColor, ppats_score: score })
    }
  }

  useEffect(() => {
    console.log('data:',data, status)
    if(status === 'success'){
      setRows(data)
    }
  },[data])
  useEffect(() => {
    console.log(
      'formData: ',
      formData,
      divideByAge(patient.age),
      dataset.gcs.select_e[divideByAge(patient.age)],
      'color: ',
      triageColor
    )
    setScore(detectPPATS({...formData, division: 0}))
  },[formData])
  useEffect(()=>{
    console.log('current:',current, current_status)
    if(current_status === 'success'){
      setFormData({...current, gcs: current.gcs_e + current.gcs_v + current.gcs_m})
    }
  },[current])
  useEffect(() => {
    setFormData((old) => { return {...old, respiratory_rate: respiratoryRate}})
  },[respiratoryRate])
  useEffect(() => {
    setFormData((old) => { return {...old, heart_rate: heartRate}})
  },[heartRate])
  useEffect(() => {
    console.log('score:',score)
    setTriageColor(detectPPATSColor(score))
  }, [score])
  useEffect(() => {
    if(isSuccess){
      handleClose()
    }
  }, [isSuccess])
  useEffect(() => {
    if(isUpdateSuccess){
      handleClose()
    }
  }, [isUpdateSuccess])
  useEffect(() => {
    setFormData((old) => { return {...old, user_id: user.id}})
  },[user])

  return (
    <GenericTemplate title="PPATS">
      <Grid item xs={12}>
        <Paper sx={{p: 3}}>
          <Button variant='contained' sx={{mb:3}} onClick={() => handleClickOpen()}>新規</Button>
          <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            onRowClick={(params) => handleClickOpen(params.row.id)}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          />
        </Paper>
      </Grid>


      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>PPATS法</DialogTitle>
        <DialogContent>
          <Typography variant="h5" sx={{mb: 3}}>
          PPATS法
          </Typography>
          <Stack spacing={4} sx={{mb: 3}}>
            <FormControl>
              <FormLabel id="level3-group-label">呼吸数</FormLabel>
              <Grid>
                <Timer name="呼吸数" times="5" setValue={setRespiratoryRate} />
                <Typography>{formData.respiratory_rate}回／分</Typography>
              </Grid>
            </FormControl>
            <FormControl>
              <FormLabel id="level4-group-label">心拍数</FormLabel>
              <Grid>
                <Timer name="心拍数" times="5" setValue={setHeartRate} />
                <Typography>{formData.heart_rate}回／分</Typography>
              </Grid>
            </FormControl>
            <Grid item sx={{ mb:2 }}>
              <FormControl>
                <FormLabel htmlFor="age-input">収縮期血圧</FormLabel>
                <TextField
                  required
                  InputProps={{
                    endAdornment: <InputAdornment position="start">mmHg</InputAdornment>,
                  }}
                  value={formData.sbp??''}
                  name="sbp"
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <FormControl>
              <FormLabel id="level4-group-label">Glasgow coma scale</FormLabel>
              <Typography>合計値：{formData.gcs===0 ? '' : formData.gcs}</Typography>
            </FormControl>
            <FormControl>
              <FormLabel id="demo-simple-select-label">E. 開眼</FormLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.gcs_e}
                name="gcs_e"
                onChange={handleChange}
              >
                <MenuItem disabled value="0"><em>選択してください</em></MenuItem>
                {dataset.gcs.select_e[divideByAge(patient.age)].length && dataset.gcs.select_e[divideByAge(patient.age)].map((item,index)=>{
                  return (
                  <MenuItem value={item.value} key={index}>{item.text}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel id="demo-simple-select-label">V. 言語音声反応</FormLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.gcs_v}
                name="gcs_v"
                onChange={handleChange}
              >
                <MenuItem disabled value="0"><em>選択してください</em></MenuItem>
                {dataset.gcs.select_e[divideByAge(patient.age)].length && dataset.gcs.select_v[divideByAge(patient.age)].map((item,index)=>{
                  return (
                  <MenuItem value={item.value} key={index}>{item.text}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel id="demo-simple-select-label">M. 最良の運動反応</FormLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.gcs_m}
                name="gcs_m"
                onChange={handleChange}
              >
                <MenuItem disabled value="0"><em>選択してください</em></MenuItem>
                {dataset.gcs.select_e[divideByAge(patient.age)].length && dataset.gcs.select_m[divideByAge(patient.age)].map((item,index)=>{
                  return (
                  <MenuItem value={item.value} key={index}>{item.text}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel id="level4-group-label">解剖学的異常所見の有無</FormLabel>
              <FormGroup row>
                {options_status==='success' && select_options.foaa.length>0 && select_options.foaa.map(item => {
                  return (
                    <FormControlLabel
                      key={item.id}
                      label={item.item}
                      control={
                        <CustomCheckbox
                          name="is_findings_of_anatomical_abnormal_id"
                          value={item.id}
                          onChange={handleChange}
                          checked={formData.is_findings_of_anatomical_abnormal_id.includes(item.id)}
                        />
                      }
                    />
                  )
                })}
              </FormGroup>
            </FormControl>
            <FormControl>
              <FormLabel id="level4-group-label">医療介入の有無</FormLabel>
              <FormGroup row>
                {options_status==='success' && select_options.mi.length>0 && select_options.mi.map(item => {
                  return (
                    <FormControlLabel
                      key={item.id}
                      label={item.item}
                      control={
                        <CustomCheckbox
                          name="is_medical_intervention_id"
                          value={item.id}
                          onChange={handleChange}
                          checked={formData.is_medical_intervention_id.includes(item.id)}
                        />
                      }
                    />
                  )
                })}
              </FormGroup>
            </FormControl>
          </Stack>
          <Grid sx={{mb: 3}}>
            <Typography variant="h6">
              トリアージ優先（{score}）
            </Typography>
            <Triage color={triageColor} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button variant="contained" onClick={handleSave}>保存</Button>
        </DialogActions>
      </Dialog>
    </GenericTemplate>
  )
}

export default Ppats;