import React, { useEffect } from 'react';
import { useNavigate, Outlet, Navigate } from 'react-router-dom';

import { useRecoilValue } from 'recoil';
import { userState } from "../../recoil/atom";

const AdminRoute = () => {
  const user = useRecoilValue(userState);
  const navigate = useNavigate();

  useEffect( () => {
    // console.log(user,user.admin!==1);
    if(user.admin!==1){
      navigate('/');
    }
  }, []);

  if(user.admin!==1){
    return <Navigate to="/" replace />
  }
  else{
    return <Outlet />;
  }
};

export default AdminRoute;