import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from 'react-router-dom';
import QRCode from "qrcode.react"
import GenericTemplate from '../templates/GenericTemplate'
import {
  Card, CardContent, CardActions,
  Paper, Grid, Stack, Divider,
  TextField, InputAdornment,
  Typography,
  FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Button, MenuItem
} from '@mui/material'
import { CustomMenuItem, CustomSelect } from "../templates/Peditape"
import { usePatient, useUpdatePatient } from '../../queries/PatientQuery';
import { dataset } from "../../lib/dataset"
import { detectTapeColor, detectDivision } from "../../lib/functions"
import { useRecoilValue } from 'recoil';
import { patientState } from "../../recoil/atom";

const Detail = () => {
  const navigate = useNavigate()
  const updatePatient = useUpdatePatient()
  const params = useParams()
  const { data, status } = usePatient(params.id)
  const [formData, setFormData] = useState({
    patient_id: '',
    age: '',
    month: '',
    tape_color: 'none',
    division: '',
    gender: '',
    weight: '',
    height: '',
    address: '',
    tel: '',
    family_name: '',
  })
  const patient = useRecoilValue(patientState)
  const [division, setDivition] = useState('')

  const handleChange = (event) => {
    setFormData((old) => { return {...old, [event.target.name]: event.target.value}})
    // 年齢・身長の更新時にはPEDITAPE_colorを更新
    if(event.target.name === 'height'){
      const height = event.target.name === 'height' ? event.target.value : formData.height
      setFormData((old) => { return {...old, tape_color: detectTapeColor(height) }})
    }
  }
  const handleSave = () => {
    updatePatient.mutate({...formData, division: division})
  }

  useEffect(() => {
    setFormData((old) => {
      return {
        ...old,
        ...patient,
      }
    })
  }, [patient])
  useEffect(() => {
    setDivition(detectDivision(formData.height))
  }, [formData])

  return (
    <GenericTemplate title="患者情報">
      <Grid item xs={12}>
        <Card sx={{p: 3}}>
          <CardContent>
            <Typography variant="h6" >患者情報</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth sx={{ mt:2, mb:2 }}>
                  <FormLabel htmlFor="name-input">
                    <Stack direction="row" spacing={1}>
                      <Typography>患者ID</Typography>
                      <Typography color="error">*</Typography>
                    </Stack>
                  </FormLabel>
                  <TextField
                    id="name-input"
                    name="patient_id"
                    required
                    value={formData.patient_id??''}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl sx={{ mt:2, mb:2 }}>
                  <FormLabel htmlFor="age-input">
                    <Stack direction="row" spacing={1}>
                      <Typography>年齢</Typography>
                      <Typography color="error">*</Typography>
                    </Stack>
                  </FormLabel>
                  <Grid container>
                    <Grid item xs={6}>
                      <TextField
                        required
                        InputProps={{
                          endAdornment: <InputAdornment position="start">歳</InputAdornment>,
                        }}
                        value={formData.age??''}
                        name="age"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        InputProps={{
                          endAdornment: <InputAdornment position="start">ヶ月</InputAdornment>,
                        }}
                        value={formData.month??''}
                        name="month"
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item xs={9}>
                <FormControl fullWidth sx={{ mb:2 }}>
                  <FormLabel id="gender-input">
                    <Stack direction="row" spacing={1}>
                      <Typography>PEDITAPE</Typography>
                      <Typography color="error">*</Typography>
                    </Stack>
                  </FormLabel>
                  <CustomSelect
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="tape_color"
                    onChange={handleChange}
                    className={formData.tape_color}
                    value={formData.tape_color??'none'}
                  >
                    <MenuItem disabled value="none"><em>判定不能</em></MenuItem>
                    {dataset.tape_color && dataset.tape_color.map((color) => {
                      return <CustomMenuItem disabled value={color.key} key={color.key} className={color.key}>{color.text}</CustomMenuItem>
                    })}
                  </CustomSelect>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <QRCode value={window.location.href} />
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ mb:2 }}>
                  <FormLabel id="gender-input">
                    <Stack direction="row" spacing={1}>
                      <Typography>性別</Typography>
                      <Typography color="error">*</Typography>
                    </Stack>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="gender-input-group-label"
                    name="gender"
                    value={formData.gender??''}
                    onChange={handleChange}
                  >
                    <FormControlLabel value="female" control={<Radio />} label="女性" />
                    <FormControlLabel value="male" control={<Radio />} label="男性" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl sx={{ mb:2 }}>
                  <FormLabel htmlFor="age-input">体重</FormLabel>
                  <TextField
                    required
                    InputProps={{
                      endAdornment: <InputAdornment position="start">kg</InputAdornment>,
                    }}
                    value={formData.weight??''}
                    name="weight"
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl sx={{ mb:2 }}>
                  <FormLabel htmlFor="height-input">
                    <Stack direction="row" spacing={1}>
                      <Typography>身長</Typography>
                      <Typography color="error">*</Typography>
                    </Stack>
                  </FormLabel>
                  <TextField
                    required
                    InputProps={{
                      endAdornment: <InputAdornment position="start">cm</InputAdornment>,
                    }}
                    value={formData.height??''}
                    name="height"
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Divider sx={{ mb:3 }} />
            <Typography variant="h6" >患者連絡先</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth sx={{ mt:2, mb:2 }}>
                  <FormLabel htmlFor="address-input">住所</FormLabel>
                  <TextField
                    id="address-input"
                    required
                    value={formData.address??''}
                    name="address"
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth sx={{ mt:2, mb:2 }}>
                  <FormLabel htmlFor="tel-input">電話番号</FormLabel>
                  <TextField
                    id="tel-input"
                    required
                    value={formData.tel??''}
                    name="tel"
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth sx={{ mb:2 }}>
                  <FormLabel htmlFor="family_name-input">家族名</FormLabel>
                  <TextField
                    id="family_name-input"
                    required
                    value={formData.family_name??''}
                    name="family_name"
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button variant="outlined" onClick={()=>{navigate(`/${params.id}`)}}>キャンセル</Button>
            <Button variant="contained" onClick={handleSave}>更新</Button>
          </CardActions>
        </Card>
      </Grid>
    </GenericTemplate>
  )
}

export default Detail