import * as api from "../api/UserApi"
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'

const useUsers = () => {
  return useQuery('users', () => api.getUsers())
}

const useUser = (id) => {
  return useQuery(
    ['user', id],
    () => api.getUser(id),
    { enabled: id > 0 }
  )
}

const useCreateUser = (formdata) => {
  const queryClient = useQueryClient()
  return useMutation(api.createUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('users')
      toast.success('登録が成功しました')
    },
    onError: (error) => {
      // console.log('error:',error)
      toast.error('登録に失敗しました')
    },
  })
}

const useUpdateUser = (id) => {
  const queryClient = useQueryClient()
  return useMutation(api.updateUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('user')
      queryClient.invalidateQueries('users')
      toast.success('更新が成功しました')
    },
    onError: (error) => {
      toast.error('更新に失敗しました')
    },
  })
}

const useDeleteUser = (id) => {
  const queryClient = useQueryClient()
  return useMutation(api.deleteUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('users')
      toast.success('削除が成功しました')
    },
    onError: (error) => {
      toast.error('削除に失敗しました')
    },
  })
}
export {
  useUsers,
  useUser,
  useCreateUser,
  useUpdateUser,
  useDeleteUser
}