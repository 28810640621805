import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import GenericTemplate from '../templates/GenericTemplate'
import Timer from '../templates/Timer';
import Triage from '../templates/Triage';
import {
  Paper, Grid, Stack, Divider,
  Dialog, DialogTitle, DialogContent, DialogActions, IconButton, 
  Typography,
  FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Button
} from '@mui/material'
import { DataGrid, GridToolbar, jaJP } from '@mui/x-data-grid';
import { useStarts, useStart, useCreateStart, useUpdateStart } from '../../queries/StartQuery';
import { detectSTART } from '../../lib/functions'
import {format, parseISO} from 'date-fns'
import { useRecoilValue } from 'recoil';
import { userState } from "../../recoil/atom";

const Start = () => {
  const navigate = useNavigate();
  const params = useParams()
  const [open, setOpen] = useState(false);
  const [triageColor, setTriageColor] = useState('')
  const user = useRecoilValue(userState)
  const default_form_data = {
    id: '',
    patient_id: params.id,
    user_id: 0,
    is_walk: '',
    spontaneous_breathing: '',
    manual_airway_control: '',
    respiratory_rate: '',
    pulse: '',
    obedience_reaction: '',
    triage_priority: '',
  }
  const [formData, setFormData] = useState(default_form_data)
  const columns = [
    {
      field: 'created_at',
      headerName: '実施日',
      width: 300,
      valueFormatter: (params) => {
        if(params.value == null) {
          return ''
        }
        else {
          return format(parseISO(params.value), "yyyy/MM/dd HH:mm")
          // return params.value
        }
      }
    },
    {
      field: 'triage_priority',
      headerName: '判定結果',
      width: 200,
      renderCell: (params) => <Triage color={params.value} />
    },
  ]
  const [rows, setRows] = useState([])
  const [currentId, setCurrentId] = useState(-1)
  const [respiratoryRate, setRespiratoryRate] = useState('')
  const [pulse, setPulse] = useState('')
  const { data, status } = useStarts(params.id)
  const { data : current, status : current_status } = useStart(currentId)
  const {isSuccess, mutate} = useCreateStart()
  const {isSuccess: isUpdateSuccess, mutate: updateMutate} = useUpdateStart()

  const handleClickOpen = (id = '') => {
    console.log('click:', id)
    if(id!==''){
      setCurrentId(id)
      // すでに取得済みの場合
      if(current_status === 'success'){
        setFormData(current)
      }
    }
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(default_form_data)
    setCurrentId(-1)
    setOpen(false)
  }
  const handleChange = (event) => {
    setFormData((old) => { return {...old, [event.target.name]: parseInt(event.target.value)}})
  }
  const handleSave = () => {
    if(formData.id===''){
      mutate({...formData, triage_priority: triageColor})
    }
    else{
      updateMutate({...formData, triage_priority: triageColor})
    }
  }

  useEffect(() => {
    console.log('formData:', formData, detectSTART(formData))
    setTriageColor(detectSTART(formData))
  },[formData])
  useEffect(() => {
    setFormData((old) => { return {...old, respiratory_rate: respiratoryRate}})
  },[respiratoryRate])
  useEffect(() => {
    setFormData((old) => { return {...old, pulse: pulse}})
  },[pulse])
  useEffect(() => {
    console.log('data:',data, status)
    if(status === 'success'){
      setRows(data)
    }
  },[data])
  useEffect(()=>{
    console.log('current:',current, current_status)
    if(current_status === 'success'){
      setFormData(current)
    }
  },[current])
  useEffect(() => {
    if(isSuccess){
    console.log('createStart', isSuccess, format(Date.now(), 'yyyy/MM/dd HH:mm:ss:SSS'))
      handleClose()
    }
  },[isSuccess])
  useEffect(() => {
    if(isUpdateSuccess){
      handleClose()
    }
  },[isUpdateSuccess])
  useEffect(() => {
  },[currentId])
  useEffect(() => {
    setFormData((old) => { return {...old, user_id: user.id}})
  },[user])

  return (
    <GenericTemplate title="START">
      <Grid item xs={12}>
        <Paper sx={{p: 3}}>
          <Button variant='contained' sx={{mb:3}} onClick={() => handleClickOpen()}>新規</Button>
          <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            onRowClick={(params) => handleClickOpen(params.row.id)}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          />
        </Paper>
      </Grid>



      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>START法{formData.created_at && <> ({format(parseISO(formData.created_at), "yyyy/MM/dd HH:mm")})</>}</DialogTitle>
        <DialogContent>
          <Typography variant="h5" sx={{mb: 3}}>
            START法
          </Typography>
          <Stack spacing={4} sx={{mb: 3}}>
            <FormControl>
              <FormLabel id="level1-group-label">歩行</FormLabel>
              <RadioGroup
                row
                aria-labelledby="is_walk-label"
                name="is_walk"
                value={formData.is_walk}
                onChange={handleChange}
              >
                <FormControlLabel value="0" control={<Radio />} label="不可" />
                <FormControlLabel value="1" control={<Radio />} label="可能" />
              </RadioGroup>
            </FormControl>
            {formData.is_walk!=='' && formData.is_walk===0 && <>
            <FormControl>
              <FormLabel id="level2-group-label">自発呼吸</FormLabel>
              <RadioGroup
                row
                aria-labelledby="level2-group-label"
                name="spontaneous_breathing"
                value={formData.spontaneous_breathing}
                onChange={handleChange}
              >
                <FormControlLabel value="1" control={<Radio />} label="あり" />
                <FormControlLabel value="0" control={<Radio />} label="なし" />
              </RadioGroup>
            </FormControl>
            {formData.spontaneous_breathing!=='' && formData.spontaneous_breathing===0 && <>
            <FormControl>
              <FormLabel id="level2_1-group-label">気道確保</FormLabel>
              <RadioGroup
                row
                aria-labelledby="level2_1-group-label"
                name="manual_airway_control"
                value={formData.manual_airway_control}
                onChange={handleChange}
              >
                <FormControlLabel value="0" control={<Radio />} label="自発呼吸なし" />
                <FormControlLabel value="1" control={<Radio />} label="自発呼吸あり" />
              </RadioGroup>
            </FormControl>
            </>}
            {formData.spontaneous_breathing!=='' && formData.spontaneous_breathing===1 && <>
            <FormControl>
              <FormLabel id="level3-group-label">呼吸数</FormLabel>
              <Grid>
                <Timer name="呼吸数" times="5" setValue={setRespiratoryRate} />
                <Typography>{formData.respiratory_rate}回／分</Typography>
              </Grid>
            </FormControl>
            {formData.respiratory_rate!=='' && formData.respiratory_rate>=10 && formData.respiratory_rate<=29 && <>
            <FormControl>
              <FormLabel id="level4-group-label">脈拍</FormLabel>
              <Grid>
                <Timer name="脈拍" times="5" setValue={setPulse} />
                <Typography>{formData.pulse}回／分</Typography>
              </Grid>
            </FormControl>
            {formData.pulse!=='' && (formData.pulse<120 || formData.pulse===-1) && <>
            <FormControl>
              <FormLabel id="level5-group-label">従命反応</FormLabel>
              <RadioGroup
                row
                aria-labelledby="level5-group-label"
                name="obedience_reaction"
                value={formData.obedience_reaction}
                onChange={handleChange}
              >
                <FormControlLabel value="1" control={<Radio />} label="あり" />
                <FormControlLabel value="0" control={<Radio />} label="なし" />
              </RadioGroup>
            </FormControl>
            </>}
            </>}
            </>}
            </>}
          </Stack>
          <Grid sx={{mb: 3}}>
            <Typography variant="h6">
              トリアージ優先
            </Typography>
            <Triage color={triageColor} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button variant="contained" onClick={handleSave}>保存</Button>
        </DialogActions>
      </Dialog>
    </GenericTemplate>
  )
}

export default Start;