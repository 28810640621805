import * as api from "../api/StartApi"
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from 'react-toastify'

const useStarts = (id) => {
  return useQuery(['starts', id], () => api.getStarts(id))
}

const useStart = (id) => {
  console.log('use start:', id, id > 0)
  return useQuery(
    ['start', id],
    () => api.getStart(id),
    { enabled: id > 0 }
  )
}

const useCreateStart = () => {
  const queryClient = useQueryClient()
  // console.log('create start')
  return useMutation(api.createStart, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('starts')
      queryClient.invalidateQueries('patient_state')
      toast.success('登録が成功しました')
    },
    onError: (error) => {
      toast.error('登録に失敗しました')
    },
  })
}

const useUpdateStart = (id) => {
  const queryClient = useQueryClient()
  return useMutation(api.updateStart, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('starts')
      queryClient.invalidateQueries('patient_state')
      toast.success('更新が成功しました')
    },
    onError: (error) => {
      toast.error('更新に失敗しました')
    },
  })
}

export {
  useStarts,
  useStart,
  useCreateStart,
  useUpdateStart,
}