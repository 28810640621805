import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import GenericTemplate from '../templates/GenericTemplate'
import Triage from '../templates/Triage';
import {
  Grid, Paper, Button,
} from "@mui/material";
import { DataGrid, GridToolbar, jaJP } from '@mui/x-data-grid';
import { usePatients } from '../../queries/PatientQuery';

const Dashboard = () => {
  const navigate = useNavigate();
  const columns = [
    {
      field: 'latest_inst',
      headerName: '最終入力した施設',
      width: 200,
      valueGetter: (params) => {
        const arr = [
          { data: new Date(params.row.latest_u), inst: params.row.name_u },
          { data: new Date(params.row.latest_s), inst: params.row.name_s },
          { data: new Date(params.row.latest_p), inst: params.row.name_p },
          { data: new Date(params.row.latest_m), inst: params.row.name_m },  
        ]
        arr.sort(
          (x, y) => (y.date) - (x.date),
        )
        return arr[0].inst
      },
    },
    {
      field: 'patient_id',
      headerName: '患者ID',
      width: 200,
    },
    {
      field: 'age',
      headerName: '年齢',
      width: 50,
    },
    {
      field: 'month',
      headerName: '月齢',
      width: 50,
    },
    {
      field: 'gender',
      headerName: '性別',
      width: 80,
      valueFormatter: (params) => {
        if (params.value == 'male') {
          return '男性';
        }
        else if (params.value == 'female') {
          return '女性'
        }
        else {
          return 'その他'
        }
      },
    },
    {
      field: 'tp_u',
      headerName: '緊急度',
      width: 100,
      renderCell: (params) => {
        if(params.value !== null)
          return <Triage color={params.value} notext={true} />
        else
          return <></>
      } 
    },
    {
      field: 'tp_s',
      headerName: 'START',
      width: 100,
      renderCell: (params) => {
        if(params.value !== null)
          return <Triage color={params.value} notext={true} />
        else
          return <></>
      } 
    },
    {
      field: 'tp_p',
      headerName: 'PPATS',
      width: 100,
      renderCell: (params) => {
        if(params.value !== null)
          return <Triage color={params.value} notext={true} />
        else
          return <></>
      } 
    },
    {
      field: 'ppats_score',
      headerName: 'PPATS点数',
      width: 100,
    },
    {
      field: 'name_m',
      headerName: '収容先医療機関',
      width: 200,
    },
  ]
  const [rows, setRows] = useState([])
  const { data, status } = usePatients()
  const handleRowClick = (params) => {
    console.log(params.row)
    navigate(`/${params.row.id}`)
  }

  useEffect(() => {
    console.log('data:',data, status)
    if(status === 'success'){
      setRows(data)
    }
  },[data])

  return (
    <GenericTemplate title="トップ" is_top={true}>
      <Grid item xs={12}>
        <Paper sx={{p: 2}}>
          <Button variant='contained' sx={{mb:3}} onClick={()=>{navigate(`/new`);}}>新規患者登録</Button>
          <DataGrid
            autoHeight
            // checkboxSelection
            components={{ Toolbar: GridToolbar }}
            rows={rows}
            columns={columns}
            onRowClick={handleRowClick}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          />
        </Paper>
      </Grid>
    </GenericTemplate>
  )
}

export default Dashboard;