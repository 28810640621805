import React from "react"
import Router from "./Router"
import { QueryClient, QueryClientProvider } from "react-query"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CssBaseline from '@mui/material/CssBaseline';

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false
      },
      mutations: {
        retry: false
      }
    }
  })
  return (
    <QueryClientProvider client={queryClient}>
      <CssBaseline />
      <Router />
      <ToastContainer hideProgressBar={true} />
    </QueryClientProvider>
  );
}

export default App;
