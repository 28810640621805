import * as api from "../api/AuthApi"
import { useQuery, useMutation } from "react-query";

import { useSetRecoilState, useResetRecoilState } from 'recoil';
import { userState } from "../recoil/atom";

import { toast } from 'react-toastify'

const useUser = () => {
  return useQuery('user', api.getUser)
}

const useLogin = () => {
  const setUser = useSetRecoilState(userState);
  return useMutation(api.login, {
    onSuccess: (user) => {
      if(user) {
        console.log('login success',user)
        setUser({
          ...user,
          isSignedIn: true,
        });
      }
    },
    onError: () => {
      toast.error('ログインに失敗しました')
    }
  })
}

const useLogout = () => {
  const resetUser = useResetRecoilState(userState);
  return useMutation(api.logout, {
    onSuccess: (user) => {
      if(user) {
        console.log('logout success',user)
        resetUser();
      }
    },
    onError: () => {
      toast.error('ログアウトに失敗しました')
    }
  })
}

export {
  useUser,
  useLogin,
  useLogout,
}