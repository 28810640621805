import React from "react";
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const Copyright = (props) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'© 小児傷病者診療ウェブシステム'}
    </Typography>
  );
}

export default Copyright;