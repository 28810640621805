import axios from "axios";
import { config } from '../lib/config'

const getPatients = async () => {
  const { data } = await axios.get(`${config.api_url}/patients`)
  return data
}

const getPatient = async (id) => {
  const { data } = await axios.get(`${config.api_url}/patients/${id}`)
  return data
}

const getPatientState = async (id) => {
  const { data } = await axios.get(`${config.api_url}/patients/${id}/state`)
  return data
}

const getPatientList = async (id) => {
  const { data } = await axios.get(`${config.api_url}/patients/${id}/list`)
  return data
}

const createPatient = async (formdata) => {
  // console.log('createPatient:',formdata)
  const { data } = await axios.post(
    `${config.api_url}/patients`,
    formdata
  )
  return data
}

const updatePatient = async (formdata) => {
  const { data } = await axios.patch(
    `${config.api_url}/patients/${formdata.id}`,
    formdata
  )
  return data
}

export {
  getPatients,
  getPatient,
  getPatientState,
  getPatientList,
  createPatient,
  updatePatient
}