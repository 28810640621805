import axios from "axios";
import { config } from '../lib/config'

axios.defaults.withCredentials = true

const getMedicalRecord = async (id) => {
  const { data } = await axios.get(`${config.api_url}/medical-record/${id}`)
  return data
}

const createMedicalRecord = async (formdata) => {
  const { data } = await axios.post(
    `${config.api_url}/patients/${formdata.patient_id}/medical-record`,
    formdata
  )
  return data
}

const updateMedicalRecord = async (formdata) => {
  const { data } = await axios.patch(
    `${config.api_url}/medical-record/${formdata.id}`,
    formdata
  )
  return data
}

const getSelectOptions = async () => {
  const { data } = await axios.get(`${config.api_url}/medical-record/select-items`)
  return data
}

export {
  getMedicalRecord,
  createMedicalRecord,
  updateMedicalRecord,
  getSelectOptions,
}