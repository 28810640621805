import axios from "axios";
import { config } from '../lib/config'

const getUsers = async () => {
  const { data } = await axios.get(`${config.api_url}/users`)
  return data
}

const getUser = async (id) => {
  const { data } = await axios.get(`${config.api_url}/users/${id}`)
  return data
}

const createUser = async (formdata) => {
  // console.log('createUser:',formdata)
  const { data } = await axios.post(
    `${config.api_url}/users`,
    formdata
  )
  return data
}

const updateUser = async (formdata) => {
  const { data } = await axios.patch(
    `${config.api_url}/users/${formdata.id}`,
    formdata
  )
  return data
}

const deleteUser = async (formdata) => {
  const {data} = await axios.delete(
    `${config.api_url}/users/${formdata.id}`,
    formdata
  )
  return data
}

export {
  getUsers,
  getUser,
  createUser,
  updateUser,
  deleteUser
}