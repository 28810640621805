import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from '../templates/Copyright';

import { useRecoilValue } from 'recoil';
import { userState } from "../../recoil/atom";

import { useLogin } from '../../queries/AuthQuery'

const theme = createTheme();

const SignIn = () => {
  const user = useRecoilValue(userState);
  const navigate = useNavigate();

  const [loginid, setLoginid] = useState('')
  const [password, setPassword] = useState('')

  const login = useLogin()

  const handleSubmit = (event) => {
    console.log(loginid, password)
    event.preventDefault();
    login.mutate({ loginid, password })
  };

  useEffect(() => {
    if(user.isSignedIn){
      navigate('/')
    }
  },[user])

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh'
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}></Avatar>
          <Typography component="h1" variant="h5">
          小児傷病者診療ウェブシステム
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="ユーザ名"
              name="loginid"
              autoComplete="name"
              autoFocus
              onChange={e => setLoginid(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="パスワード"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={e => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, fontSize: '1.2rem' }}
            >
              ログイン
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

export default SignIn