import axios from "axios";
import { config } from '../lib/config'

axios.defaults.withCredentials = true

const getPpatss = async (id) => {
  const { data } = await axios.get(`${config.api_url}/patients/${id}/ppats`)
  return data
}

const getPpats = async (id) => {
  const { data } = await axios.get(`${config.api_url}/ppats/${id}`)
  return data
}

const createPpats = async (formdata) => {
  const { data } = await axios.post(
    `${config.api_url}/patients/${formdata.patient_id}/ppats`,
    formdata
  )
  return data
}

const updatePpats = async (formdata) => {
  const { data } = await axios.patch(
    `${config.api_url}/ppats/${formdata.id}`,
    formdata
  )
  return data
}

const getSelectOptions = async () => {
  const { data } = await axios.get(`${config.api_url}/ppats/select-items`)
  return data
}

export {
  getPpatss,
  getPpats,
  createPpats,
  updatePpats,
  getSelectOptions,
}