import React, {useState, useEffect} from 'react'
import {
  Stack,
  Button, Typography, 
  Dialog, DialogTitle, DialogContent, DialogActions, IconButton, 
} from '@mui/material'
import {
  Close as CloseIcon,
} from '@mui/icons-material';



const Timer = (props) => {
  const [open, setOpen] = useState(false);
  const {times, name, setValue, others} = props

  const [displayTime, setDisplayTime] = useState("00:00:000")
  const [startTime, setStartTime] = useState(0)
  const [calcTime, setCalcTime] = useState(0)
  const [running, setRunning] = useState(false)

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = () => {
    setValue(Math.round(calcTime!==0 ? 60000*times/calcTime : 0))
    setOpen(false);
  };

  const onClickStart = () => {
    setStartTime(Date.now() - calcTime)
    setRunning(true)
  }
  const onClickStop = () => {
    setRunning(false)
  }
  const onClickReset = () => {
    setRunning(false)
    setDisplayTime("00:00:000")
    setCalcTime(0)
  }

  // ボタンクリックの処理
  useEffect(() => {
    let timerInterval = undefined
    if(running){
      timerInterval = window.setInterval(() => {
        setCalcTime(Date.now() - startTime)
      }, 10)
    }
    return () => {
      window.clearInterval(timerInterval)
    }
  }, [running])

  // タイムスタンプを変換
  useEffect(() => {
    // console.log('calcTime',calcTime)
    // console.log('timer',calcTime!=0 ? 60000*times/calcTime : 0)
    const currentTime = new Date(calcTime)
    const m = String(currentTime.getMinutes()).padStart(2,"0")
    const s = String(currentTime.getSeconds()).padStart(2,"0")
    const ms = String(currentTime.getMilliseconds()).padStart(3,"0")
    setDisplayTime(`${m}:${s}:${ms}`)
  },[calcTime])

  return (
    <>
      <Button variant='contained' onClick={()=>{handleClickOpen()}}>測定</Button>

      <Dialog
        maxWidth='lg'
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{name}：{times}回</DialogTitle>
        <DialogContent>
          <Typography variant="h4" sx={{mb: 3}}>
            {displayTime}
          </Typography>
          <Stack direction="row" spacing={3}>
            <Button onClick={onClickStart} variant="contained" disabled={running}>開始</Button>
            <Button onClick={onClickStop} variant="contained" disabled={!running}>停止</Button>
            <Button onClick={onClickReset} variant="outlined" color="info">リセット</Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button variant="contained" onClick={handleSave}>保存</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Timer