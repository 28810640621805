import React from "react"
import { styled } from '@mui/material/styles';
import {
  Card, CardHeader, Typography
} from '@mui/material'

const CustomCard = styled(Card)({
  width: '100%',
  border: '1px solid',
  "&.green": {
    backgroundColor: 'green',
    color: 'white',
  },
  "&.black": {
    backgroundColor: 'black',
    color: 'white',
  },
  "&.red": {
    backgroundColor: 'red',
    color: 'white',
  },
  "&.yellow": {
    backgroundColor: 'yellow',
    color: '#bbb',
  },
});

const getText = (color) => {
  if(color === 'green')  return '待機群'
  if(color === 'black')  return '救命不能群'
  if(color === 'red')    return '最優先治療群'
  if(color === 'yellow') return '非緊急治療群'
  else return '判定不能'
}
const Triage = ({color, notext=false}) => {
  return (
    <CustomCard elevation={0} className={color} square>
      <CardHeader title={
        <Typography align="center">{notext===true?'　　　':getText(color)}</Typography>
      }>
        
      </CardHeader>
    </CustomCard>
  )
}

export default Triage