export const dataset = {

  tape_color: [
    {
      key: 'gray',
      text: '灰',
    },
    {
      key: 'pink',
      text: '桃',
    },
    {
      key: 'red',
      text: '赤',
    },
    {
      key: 'purple',
      text: '紫',
    },
    {
      key: 'yellow',
      text: '黃',
    },
    {
      key: 'white',
      text: '白',
    },
    {
      key: 'blue',
      text: '青',
    },
    {
      key: 'orange',
      text: '橙',
    },
    {
      key: 'green',
      text: '緑',
    },
  ],

  gcs: {
    select_e: {
      set_a: [
        {
          text: '自発的に',
          value: 4,
        },
        {
          text: '呼びかけにより',
          value: 3,
        },
        {
          text: '痛み刺激により',
          value: 2,
        },
        {
          text: '開眼しない',
          value: 1,
        },
      ],
      set_b: [
        {
          text: '自発的に',
          value: 4,
        },
        {
          text: '呼びかけにより',
          value: 3,
        },
        {
          text: '痛み刺激により',
          value: 2,
        },
        {
          text: '開眼しない',
          value: 1,
        },
      ],
      set_c: [
        {
          text: '自発的に',
          value: 4,
        },
        {
          text: '呼びかけにより',
          value: 3,
        },
        {
          text: '痛み刺激により',
          value: 2,
        },
        {
          text: '開眼しない',
          value: 1,
        },
      ],
    },
    select_v: {
      set_a: [
        {
          text: '笑い・喃語',
          value: 5,
        },
        {
          text: '啼泣・易刺激性',
          value: 4,
        },
        {
          text: '痛み刺激で啼泣',
          value: 3,
        },
        {
          text: '痛み刺激でうめき声',
          value: 2,
        },
        {
          text: '発声しない',
          value: 1,
        },
      ],
      set_b: [
        {
          text: '年齢相応な単語や会話',
          value: 5,
        },
        {
          text: '混乱した単語や会話',
          value: 4,
        },
        {
          text: '不適当な発語',
          value: 3,
        },
        {
          text: 'うめき声',
          value: 2,
        },
        {
          text: '発声しない',
          value: 1,
        },
      ],
      set_c: [
        {
          text: '見当識あり',
          value: 5,
        },
        {
          text: '混乱した会話',
          value: 4,
        },
        {
          text: '不適当な発語',
          value: 3,
        },
        {
          text: '無意味な発声',
          value: 2,
        },
        {
          text: '発声しない',
          value: 1,
        },
      ],
    },
    select_m: {
      set_a: [
        {
          text: '自発的な目的のある動作',
          value: 6,
        },
        {
          text: '接触からの逃避',
          value: 5,
        },
        {
          text: '痛み刺激からの逃避',
          value: 4,
        },
        {
          text: '上肢の異常屈曲',
          value: 3,
        },
        {
          text: '四肢の異常伸展',
          value: 2,
        },
        {
          text: '全く動かない',
          value: 1,
        },
      ],
      set_b: [
        {
          text: '指示に従う',
          value: 6,
        },
        {
          text: '痛み刺激部位に手足をもってくる',
          value: 5,
        },
        {
          text: '痛み刺激からの逃避',
          value: 4,
        },
        {
          text: '上肢の異常屈曲',
          value: 3,
        },
        {
          text: '四肢の異常伸展',
          value: 2,
        },
        {
          text: '全く動かない',
          value: 1,
        },
      ],
      set_c: [
        {
          text: '指示に従う',
          value: 6,
        },
        {
          text: '痛み刺激部位に手足をもってくる',
          value: 5,
        },
        {
          text: '痛み刺激からの逃避',
          value: 4,
        },
        {
          text: '上肢の異常屈曲',
          value: 3,
        },
        {
          text: '四肢の異常伸展',
          value: 2,
        },
        {
          text: '全く動かない',
          value: 1,
        },
      ],
    },
  },

  division: [
    {
      division: 0,
      age: 0, // 年齢（年）
      month: 0, // 年齢（月）
      height: ['>= 46', '< 52'], // 身長（条件式配列）
      tape_color: 'gray',
    },
    {
      division: 1,
      age: 0,
      month: 1,
      height: ['>= 52', '< 55'],
      color: 'gray',
    },
    {
      division: 2,
      age: 0,
      month: 2,
      height: ['>= 55', '< 59'],
      color: 'gray',
    },
    {
      division: 3,
      age: 0,
      month: 3,
      height: ['>= 59', '< 62'],
      color: 'pink',
    },
    {
      division: 4,
      age: 0,
      month: 4,
      height: ['>= 62', '< 64'],
      color: 'pink',
    },
    {
      division: 5,
      age: 0,
      month: 5,
      height: ['>= 64', '< 66'],
      color: 'pink',
    },
    {
      division: 6,
      age: 0,
      month: 6,
      height: ['>= 66', '< 67'],
      color: 'pink',
    },
    {
      division: 7,
      age: 0,
      month: 7,
      height: ['>= 67', '< 69'],
      color: 'red',
    },
    {
      division: 8,
      age: 0,
      month: 8,
      height: ['>= 69', '< 71'],
      color: 'red',
    },
    {
      division: 9,
      age: 0,
      month: 9,
      height: ['>= 71', '< 72'],
      color: 'red',
    },
    {
      division: 10,
      age: 0,
      month: 10,
      height: ['>= 72', '< 73'],
      color: 'red',
    },
    {
      division: 11,
      age: 0,
      month: 11,
      height: ['>= 73', '< 74'],
      color: 'red',
    },
    {
      division: 12,
      age: 1,
      month: 0,
      height: ['>= 74', '< 84'],
      color: 'purple',
    },
    {
      division: 13,
      age: 2,
      month: 0,
      height: ['>= 84', '< 94'],
      color: 'yellow',
    },
    {
      division: 14,
      age: 3,
      month: 0,
      height: ['>= 94', '< 98'],
      color: 'yellow',
    },
    {
      division: 15,
      age: 4,
      month: 0,
      height: ['>= 98', '< 106'],
      color: 'white',
    },
    {
      division: 16,
      age: 5,
      month: 0,
      height: ['>= 106', '< 114'],
      color: 'blue',
    },
    {
      division: 17,
      age: 6,
      month: 0,
      height: ['>= 114', '< 122'],
      color: 'blue',
    },
    {
      division: 18,
      age: 7,
      month: 0,
      height: ['>= 122', '< 126'],
      color: 'orange',
    },
    {
      division: 19,
      age: 8,
      month: 0,
      height: ['>= 126', '< 131'],
      color: 'orange',
    },
    {
      division: 20,
      age: 9,
      month: 0,
      height: ['>= 131', '< 138'],
      color: 'green',
    },
    {
      division: 21,
      age: 10,
      month: 0,
      height: ['>= 138', '< 143'],
      color: 'green',
    },
    {
      division: 22,
      age: 11,
      month: 0,
      height: ['>= 143', '< 147'],
      color: 'green',
    },
    {
      division: 23,
      age: 12,
      month: 0,
      height: ['>= 147'],
      color: 'green',
    },
  ],
  // 呼吸数
  ppats_respiratory_rate: [
    {
      division: 0, // 判定区分（エクセルの左から0-23の範囲で）
      range: [
        {
          conditions: ['>= 41', '<= 54'],
          point: 0
        },
        {
          conditions: ['>= 36', '<= 40'],
          point: 1
        },
        {
          conditions: ['>= 55', '<= 60'],
          point: 1
        },
        {
          conditions: ['>= 26', '<= 35'],
          point: 2
        },
        {
          conditions: ['>= 61', '<= 69'],
          point: 2
        },
        {
          conditions: ['< 26'],
          point: 3
        },
        {
          conditions: ['> 69'],
          point: 3
        },
      ],
    },
    {
      division: 1,
      range: [
        {
          conditions: ['>= 40', '<= 53'],
          point: 0
        },
        {
          conditions: ['>= 35', '<= 39'],
          point: 1
        },
        {
          conditions: ['>= 54', '<= 59'],
          point: 1
        },
        {
          conditions: ['>= 26', '<= 34'],
          point: 2
        },
        {
          conditions: ['>= 60', '<= 68'],
          point: 2
        },
        {
          conditions: ['< 26'],
          point: 3
        },
        {
          conditions: ['> 68'],
          point: 3
        },
      ],
    },
    {
      division: 2,
      range: [
        {
          conditions: ['>= 40', '<= 52'],
          point: 0
        },
        {
          conditions: ['>= 35', '<= 39'],
          point: 1
        },
        {
          conditions: ['>= 53', '<= 58'],
          point: 1
        },
        {
          conditions: ['>= 25', '<= 34'],
          point: 2
        },
        {
          conditions: ['>= 59', '<= 68'],
          point: 2
        },
        {
          conditions: ['< 25'],
          point: 3
        },
        {
          conditions: ['> 68'],
          point: 3
        },
      ],
    },
    {
      division: 3,
      range: [
        {
          conditions: ['>= 40', '<= 52'],
          point: 0
        },
        {
          conditions: ['>= 34', '<= 39'],
          point: 1
        },
        {
          conditions: ['>= 53', '<= 57'],
          point: 1
        },
        {
          conditions: ['>= 25', '<= 33'],
          point: 2
        },
        {
          conditions: ['>= 58', '<= 67'],
          point: 2
        },
        {
          conditions: ['< 25'],
          point: 3
        },
        {
          conditions: ['> 67'],
          point: 3
        },
      ],
    },
    {
      division: 4,
      range: [
        {
          conditions: ['>= 39', '<= 51'],
          point: 0
        },
        {
          conditions: ['>= 34', '<= 38'],
          point: 1
        },
        {
          conditions: ['>= 52', '<= 56'],
          point: 1
        },
        {
          conditions: ['>= 24', '<= 33'],
          point: 2
        },
        {
          conditions: ['>= 57', '<= 65'],
          point: 2
        },
        {
          conditions: ['< 24'],
          point: 3
        },
        {
          conditions: ['> 65'],
          point: 3
        },
      ],
    },
    {
      division: 5,
      range: [
        {
          conditions: ['>= 39', '<= 50'],
          point: 0
        },
        {
          conditions: ['>= 33', '<= 38'],
          point: 1
        },
        {
          conditions: ['>= 51', '<= 56'],
          point: 1
        },
        {
          conditions: ['>= 24', '<= 32'],
          point: 2
        },
        {
          conditions: ['>= 57', '<= 65'],
          point: 2
        },
        {
          conditions: ['< 24'],
          point: 3
        },
        {
          conditions: ['> 65'],
          point: 3
        },
      ],
    },
    {
      division: 6,
      range: [
        {
          conditions: ['>= 38', '<= 50'],
          point: 0
        },
        {
          conditions: ['>= 32', '<= 37'],
          point: 1
        },
        {
          conditions: ['>= 51', '<= 55'],
          point: 1
        },
        {
          conditions: ['>= 24', '<= 31'],
          point: 2
        },
        {
          conditions: ['>= 56', '<= 64'],
          point: 2
        },
        {
          conditions: ['< 24'],
          point: 3
        },
        {
          conditions: ['> 64'],
          point: 3
        },
      ],
    },
    {
      division: 7,
      range: [
        {
          conditions: ['>= 37', '<= 49'],
          point: 0
        },
        {
          conditions: ['>= 32', '<= 36'],
          point: 1
        },
        {
          conditions: ['>= 50', '<= 54'],
          point: 1
        },
        {
          conditions: ['>= 23', '<= 31'],
          point: 2
        },
        {
          conditions: ['>= 55', '<= 62'],
          point: 2
        },
        {
          conditions: ['< 23'],
          point: 3
        },
        {
          conditions: ['> 62'],
          point: 3
        },
      ],
    },
    {
      division: 8,
      range: [
        {
          conditions: ['>= 37', '<= 48'],
          point: 0
        },
        {
          conditions: ['>= 31', '<= 36'],
          point: 1
        },
        {
          conditions: ['>= 49', '<= 53'],
          point: 1
        },
        {
          conditions: ['>= 23', '<= 30'],
          point: 2
        },
        {
          conditions: ['>= 54', '<= 61'],
          point: 2
        },
        {
          conditions: ['< 23'],
          point: 3
        },
        {
          conditions: ['> 61'],
          point: 3
        },
      ],
    },
    {
      division: 9,
      range: [
        {
          conditions: ['>= 36', '<= 47'],
          point: 0
        },
        {
          conditions: ['>= 31', '<= 35'],
          point: 1
        },
        {
          conditions: ['>= 48', '<= 52'],
          point: 1
        },
        {
          conditions: ['>= 22', '<= 30'],
          point: 2
        },
        {
          conditions: ['>= 53', '<= 60'],
          point: 2
        },
        {
          conditions: ['< 22'],
          point: 3
        },
        {
          conditions: ['> 60'],
          point: 3
        },
      ],
    },
    {
      division: 10,
      range: [
        {
          conditions: ['>= 36', '<= 47'],
          point: 0
        },
        {
          conditions: ['>= 31', '<= 35'],
          point: 1
        },
        {
          conditions: ['>= 48', '<= 51'],
          point: 1
        },
        {
          conditions: ['>= 22', '<= 30'],
          point: 2
        },
        {
          conditions: ['>= 52', '<= 60'],
          point: 2
        },
        {
          conditions: ['< 22'],
          point: 3
        },
        {
          conditions: ['> 60'],
          point: 3
        },
      ],
    },
    {
      division: 11,
      range: [
        {
          conditions: ['>= 35', '<= 46'],
          point: 0
        },
        {
          conditions: ['>= 30', '<= 34'],
          point: 1
        },
        {
          conditions: ['>= 47', '<= 50'],
          point: 1
        },
        {
          conditions: ['>= 22', '<= 29'],
          point: 2
        },
        {
          conditions: ['>= 51', '<= 59'],
          point: 2
        },
        {
          conditions: ['< 22'],
          point: 3
        },
        {
          conditions: ['> 59'],
          point: 3
        },
      ],
    },
    {
      division: 12,
      range: [
        {
          conditions: ['>= 35', '<= 45'],
          point: 0
        },
        {
          conditions: ['>= 30', '<= 34'],
          point: 1
        },
        {
          conditions: ['>= 46', '<= 50'],
          point: 1
        },
        {
          conditions: ['>= 22', '<= 29'],
          point: 2
        },
        {
          conditions: ['>= 51', '<= 58'],
          point: 2
        },
        {
          conditions: ['< 22'],
          point: 3
        },
        {
          conditions: ['> 58'],
          point: 3
        },
      ],
    },
    {
      division: 13,
      range: [
        {
          conditions: ['>= 27', '<= 34'],
          point: 0
        },
        {
          conditions: ['>= 24', '<= 26'],
          point: 1
        },
        {
          conditions: ['>= 35', '<= 37'],
          point: 1
        },
        {
          conditions: ['>= 18', '<= 23'],
          point: 2
        },
        {
          conditions: ['>= 38', '<= 42'],
          point: 2
        },
        {
          conditions: ['< 18'],
          point: 3
        },
        {
          conditions: ['> 42'],
          point: 3
        },
      ],
    },
    {
      division: 14,
      range: [
        {
          conditions: ['>= 24', '<= 29'],
          point: 0
        },
        {
          conditions: ['>= 21', '<= 23'],
          point: 1
        },
        {
          conditions: ['>= 30', '<= 31'],
          point: 1
        },
        {
          conditions: ['>= 17', '<= 20'],
          point: 2
        },
        {
          conditions: ['>= 32', '<= 36'],
          point: 2
        },
        {
          conditions: ['< 17'],
          point: 3
        },
        {
          conditions: ['> 36'],
          point: 3
        },
      ],
    },
    {
      division: 15,
      range: [
        {
          conditions: ['>= 22', '<= 27'],
          point: 0
        },
        {
          conditions: ['>= 20', '<= 21'],
          point: 1
        },
        {
          conditions: ['>= 28', '<= 29'],
          point: 1
        },
        {
          conditions: ['>= 17', '<= 19'],
          point: 2
        },
        {
          conditions: ['>= 30', '<= 32'],
          point: 2
        },
        {
          conditions: ['< 17'],
          point: 3
        },
        {
          conditions: ['> 32'],
          point: 3
        },
      ],
    },
    {
      division: 16,
      range: [
        {
          conditions: ['>= 21', '<= 25'],
          point: 0
        },
        {
          conditions: ['= 20'],
          point: 1
        },
        {
          conditions: ['>= 26', '<= 27'],
          point: 1
        },
        {
          conditions: ['>= 17', '<= 19'],
          point: 2
        },
        {
          conditions: ['>= 28', '<= 30'],
          point: 2
        },
        {
          conditions: ['< 17'],
          point: 3
        },
        {
          conditions: ['> 30'],
          point: 3
        },
      ],
    },
    {
      division: 17,
      range: [
        {
          conditions: ['>= 20', '<= 24'],
          point: 0
        },
        {
          conditions: ['= 19'],
          point: 1
        },
        {
          conditions: ['= 25'],
          point: 1
        },
        {
          conditions: ['>= 16', '<= 18'],
          point: 2
        },
        {
          conditions: ['>= 26', '<= 28'],
          point: 2
        },
        {
          conditions: ['< 16'],
          point: 3
        },
        {
          conditions: ['> 28'],
          point: 3
        },
      ],
    },
    {
      division: 18,
      range: [
        {
          conditions: ['>= 20', '<= 23'],
          point: 0
        },
        {
          conditions: ['>= 18', '<= 19'],
          point: 1
        },
        {
          conditions: ['= 24'],
          point: 1
        },
        {
          conditions: ['>= 16', '<= 17'],
          point: 2
        },
        {
          conditions: ['>= 25', '<= 27'],
          point: 2
        },
        {
          conditions: ['< 16'],
          point: 3
        },
        {
          conditions: ['> 27'],
          point: 3
        },
      ],
    },
    {
      division: 19,
      range: [
        {
          conditions: ['>= 19', '<= 22'],
          point: 0
        },
        {
          conditions: ['>= 17', '<= 18'],
          point: 1
        },
        {
          conditions: ['>= 23', '<= 24'],
          point: 1
        },
        {
          conditions: ['>= 15', '<= 16'],
          point: 2
        },
        {
          conditions: ['>= 25', '<= 26'],
          point: 2
        },
        {
          conditions: ['< 15'],
          point: 3
        },
        {
          conditions: ['> 26'],
          point: 3
        },
      ],
    },
    {
      division: 20,
      range: [
        {
          conditions: ['>= 19', '<= 21'],
          point: 0
        },
        {
          conditions: ['>= 17', '<= 18'],
          point: 1
        },
        {
          conditions: ['>= 22', '<= 23'],
          point: 1
        },
        {
          conditions: ['>= 14', '<= 16'],
          point: 2
        },
        {
          conditions: ['>= 24', '<= 26'],
          point: 2
        },
        {
          conditions: ['< 14'],
          point: 3
        },
        {
          conditions: ['> 26'],
          point: 3
        },
      ],
    },
    {
      division: 21,
      range: [
        {
          conditions: ['>= 18', '<= 21'],
          point: 0
        },
        {
          conditions: ['>= 16', '<= 17'],
          point: 1
        },
        {
          conditions: ['= 22'],
          point: 1
        },
        {
          conditions: ['>= 14', '<= 15'],
          point: 2
        },
        {
          conditions: ['>= 23', '<= 25'],
          point: 2
        },
        {
          conditions: ['< 14'],
          point: 3
        },
        {
          conditions: ['> 25'],
          point: 3
        },
      ],
    },
    {
      division: 22,
      range: [
        {
          conditions: ['>= 18', '<= 20'],
          point: 0
        },
        {
          conditions: ['>= 16', '<= 17'],
          point: 1
        },
        {
          conditions: ['>= 21', '<= 22'],
          point: 1
        },
        {
          conditions: ['>= 13', '<= 15'],
          point: 2
        },
        {
          conditions: ['>= 23', '<= 25'],
          point: 2
        },
        {
          conditions: ['< 13'],
          point: 3
        },
        {
          conditions: ['> 25'],
          point: 3
        },
      ],
    },
    {
      division: 23,
      range: [
        {
          conditions: ['>= 17', '<= 20'],
          point: 0
        },
        {
          conditions: ['>= 15', '<= 16'],
          point: 1
        },
        {
          conditions: ['= 21'],
          point: 1
        },
        {
          conditions: ['>= 12', '<= 14'],
          point: 2
        },
        {
          conditions: ['>= 22', '<= 24'],
          point: 2
        },
        {
          conditions: ['< 12'],
          point: 3
        },
        {
          conditions: ['> 24'],
          point: 3
        },
      ],
    },
  ],
  // 心拍
  ppats_heart_rate: [
    {
      division: 0,
      range: [
        {
          conditions: ['>= 113', '<= 134'],
          point: 0
        },
        {
          conditions: ['>= 103', '<= 112'],
          point: 1
        },
        {
          conditions: ['>= 135', '<= 143'],
          point: 1
        },
        {
          conditions: ['>= 86', '<= 102'],
          point: 2
        },
        {
          conditions: ['>= 144', '<= 159'],
          point: 2
        },
        {
          conditions: ['< 86'],
          point: 3
        },
        {
          conditions: ['> 159'],
          point: 3
        },
      ],
    },
    {
      division: 1,
      range: [
        {
          conditions: ['>= 130', '<= 150'],
          point: 0
        },
        {
          conditions: ['>= 121', '<= 129'],
          point: 1
        },
        {
          conditions: ['>= 151', '<= 160'],
          point: 1
        },
        {
          conditions: ['>= 105', '<= 120'],
          point: 2
        },
        {
          conditions: ['>= 161', '<= 176'],
          point: 2
        },
        {
          conditions: ['< 105'],
          point: 3
        },
        {
          conditions: ['> 176'],
          point: 3
        },
      ],
    },
    {
      division: 2,
      range: [
        {
          conditions: ['>= 128', '<= 149'],
          point: 0
        },
        {
          conditions: ['>= 120', '<= 127'],
          point: 1
        },
        {
          conditions: ['>= 150', '<= 159'],
          point: 1
        },
        {
          conditions: ['>= 104', '<= 119'],
          point: 2
        },
        {
          conditions: ['>= 160', '<= 175'],
          point: 2
        },
        {
          conditions: ['< 104'],
          point: 3
        },
        {
          conditions: ['> 175'],
          point: 3
        },
      ],
    },
    {
      division: 3,
      range: [
        {
          conditions: ['>= 128', '<= 148'],
          point: 0
        },
        {
          conditions: ['>= 118', '<= 127'],
          point: 1
        },
        {
          conditions: ['>= 149', '<= 156'],
          point: 1
        },
        {
          conditions: ['>= 103', '<= 117'],
          point: 2
        },
        {
          conditions: ['>= 157', '<= 173'],
          point: 2
        },
        {
          conditions: ['< 103'],
          point: 3
        },
        {
          conditions: ['> 173'],
          point: 3
        },
      ],
    },
    {
      division: 4,
      range: [
        {
          conditions: ['>= 125', '<= 145'],
          point: 0
        },
        {
          conditions: ['>=116', '<= 124'],
          point: 1
        },
        {
          conditions: ['>= 146', '<= 154'],
          point: 1
        },
        {
          conditions: ['>= 101', '<= 115'],
          point: 2
        },
        {
          conditions: ['>= 155', '<= 170'],
          point: 2
        },
        {
          conditions: ['< 101'],
          point: 3
        },
        {
          conditions: ['> 170'],
          point: 3
        },
      ],
    },
    {
      division: 5,
      range: [
        {
          conditions: ['>= 123', '<= 143'],
          point: 0
        },
        {
          conditions: ['>= 114', '<= 122'],
          point: 1
        },
        {
          conditions: ['>= 144', '<= 151'],
          point: 1
        },
        {
          conditions: ['>= 99', '<= 113'],
          point: 2
        },
        {
          conditions: ['>= 152', '<= 168'],
          point: 2
        },
        {
          conditions: ['< 99'],
          point: 3
        },
        {
          conditions: ['> 168'],
          point: 3
        },
      ],
    },
    {
      division: 6,
      range: [
        {
          conditions: ['>= 121', '<= 141'],
          point: 0
        },
        {
          conditions: ['>= 113', '<= 120'],
          point: 1
        },
        {
          conditions: ['>= 142', '<= 150'],
          point: 1
        },
        {
          conditions: ['>= 98', '<= 112'],
          point: 2
        },
        {
          conditions: ['>= 151', '<= 165'],
          point: 2
        },
        {
          conditions: ['< 98'],
          point: 3
        },
        {
          conditions: ['> 165'],
          point: 3
        },
      ],
    },
    {
      division: 7,
      range: [
        {
          conditions: ['>= 119', '<= 139'],
          point: 0
        },
        {
          conditions: ['>= 111', '<= 118'],
          point: 1
        },
        {
          conditions: ['>= 140', '<= 148'],
          point: 1
        },
        {
          conditions: ['>= 95', '<= 110'],
          point: 2
        },
        {
          conditions: ['>= 149', '<= 164'],
          point: 2
        },
        {
          conditions: ['< 95'],
          point: 3
        },
        {
          conditions: ['> 164'],
          point: 3
        },
      ],
    },
    {
      division: 8,
      range: [
        {
          conditions: ['>= 118', '<= 136'],
          point: 0
        },
        {
          conditions: ['>= 108', '<= 117'],
          point: 1
        },
        {
          conditions: ['>= 137', '<= 145'],
          point: 1
        },
        {
          conditions: ['>= 94', '<= 107'],
          point: 2
        },
        {
          conditions: ['>= 146', '<= 161'],
          point: 2
        },
        {
          conditions: ['< 94'],
          point: 3
        },
        {
          conditions: ['> 161'],
          point: 3
        },
      ],
    },
    {
      division: 9,
      range: [
        {
          conditions: ['>= 115', '<= 135'],
          point: 0
        },
        {
          conditions: ['>= 106', '<= 114'],
          point: 1
        },
        {
          conditions: ['>= 136', '<= 143'],
          point: 1
        },
        {
          conditions: ['>= 91', '<= 105'],
          point: 2
        },
        {
          conditions: ['>= 144', '<= 159'],
          point: 2
        },
        {
          conditions: ['< 91'],
          point: 3
        },
        {
          conditions: ['> 159'],
          point: 3
        },
      ],
    },
    {
      division: 10,
      range: [
        {
          conditions: ['>= 114', '<= 133'],
          point: 0
        },
        {
          conditions: ['>= 105', '<= 113'],
          point: 1
        },
        {
          conditions: ['>= 134', '<= 141'],
          point: 1
        },
        {
          conditions: ['>= 90', '<= 104'],
          point: 2
        },
        {
          conditions: ['>= 142', '<= 156'],
          point: 2
        },
        {
          conditions: ['< 90'],
          point: 3
        },
        {
          conditions: ['> 156'],
          point: 3
        },
      ],
    },
    {
      division: 11,
      range: [
        {
          conditions: ['>= 113', '<= 131'],
          point: 0
        },
        {
          conditions: ['>= 104', '<= 112'],
          point: 1
        },
        {
          conditions: ['>= 132', '<= 139'],
          point: 1
        },
        {
          conditions: ['>= 90', '<= 103'],
          point: 2
        },
        {
          conditions: ['>= 140', '<= 155'],
          point: 2
        },
        {
          conditions: ['< 90'],
          point: 3
        },
        {
          conditions: ['> 155'],
          point: 3
        },
      ],
    },
    {
      division: 12,
      range: [
        {
          conditions: ['>= 111', '<= 130'],
          point: 0
        },
        {
          conditions: ['>= 103', '<= 110'],
          point: 1
        },
        {
          conditions: ['>= 131', '<= 138'],
          point: 1
        },
        {
          conditions: ['>= 88', '<= 102'],
          point: 2
        },
        {
          conditions: ['>= 139', '<= 154'],
          point: 2
        },
        {
          conditions: ['< 88'],
          point: 3
        },
        {
          conditions: ['> 154'],
          point: 3
        },
      ],
    },
    {
      division: 13,
      range: [
        {
          conditions: ['>= 100', '<= 119'],
          point: 0
        },
        {
          conditions: ['>= 91', '<= 99'],
          point: 1
        },
        {
          conditions: ['>= 120', '<= 128'],
          point: 1
        },
        {
          conditions: ['>= 78', '<= 90'],
          point: 2
        },
        {
          conditions: ['>= 129', '<= 141'],
          point: 2
        },
        {
          conditions: ['< 78'],
          point: 3
        },
        {
          conditions: ['> 141'],
          point: 3
        },
      ],
    },
    {
      division: 14,
      range: [
        {
          conditions: ['>= 93', '<= 111'],
          point: 0
        },
        {
          conditions: ['>= 85', '<= 92'],
          point: 1
        },
        {
          conditions: ['>= 112', '<= 120'],
          point: 1
        },
        {
          conditions: ['>= 70', '<= 84'],
          point: 2
        },
        {
          conditions: ['>= 121', '<= 134'],
          point: 2
        },
        {
          conditions: ['< 70'],
          point: 3
        },
        {
          conditions: ['> 134'],
          point: 3
        },
      ],
    },
    {
      division: 15,
      range: [
        {
          conditions: ['>= 89', '<= 108'],
          point: 0
        },
        {
          conditions: ['>= 81', '<= 88'],
          point: 1
        },
        {
          conditions: ['>= 109', '<= 116'],
          point: 1
        },
        {
          conditions: ['>= 66', '<= 80'],
          point: 2
        },
        {
          conditions: ['>= 117', '<= 130'],
          point: 2
        },
        {
          conditions: ['< 66'],
          point: 3
        },
        {
          conditions: ['> 130'],
          point: 3
        },
      ],
    },
    {
      division: 16,
      range: [
        {
          conditions: ['>= 86', '<= 104'],
          point: 0
        },
        {
          conditions: ['>= 79', '<= 85'],
          point: 1
        },
        {
          conditions: ['>= 105', '<= 114'],
          point: 1
        },
        {
          conditions: ['>= 63', '<= 78'],
          point: 2
        },
        {
          conditions: ['>= 115', '<= 126'],
          point: 2
        },
        {
          conditions: ['< 63'],
          point: 3
        },
        {
          conditions: ['> 126'],
          point: 3
        },
      ],
    },
    {
      division: 17,
      range: [
        {
          conditions: ['>= 83', '<= 100'],
          point: 0
        },
        {
          conditions: ['>= 75', '<= 82'],
          point: 1
        },
        {
          conditions: ['>= 101', '<= 110'],
          point: 1
        },
        {
          conditions: ['>= 60', '<= 74'],
          point: 2
        },
        {
          conditions: ['>= 111', '<= 123'],
          point: 2
        },
        {
          conditions: ['< 60'],
          point: 3
        },
        {
          conditions: ['> 123'],
          point: 3
        },
      ],
    },
    {
      division: 18,
      range: [
        {
          conditions: ['>= 79', '<= 98'],
          point: 0
        },
        {
          conditions: ['>= 73', '<= 78'],
          point: 1
        },
        {
          conditions: ['>= 99', '<= 106'],
          point: 1
        },
        {
          conditions: ['>= 56', '<= 72'],
          point: 2
        },
        {
          conditions: ['>= 107', '<= 119'],
          point: 2
        },
        {
          conditions: ['< 56'],
          point: 3
        },
        {
          conditions: ['> 119'],
          point: 3
        },
      ],
    },
    {
      division: 19,
      range: [
        {
          conditions: ['>= 76', '<= 95'],
          point: 0
        },
        {
          conditions: ['>= 69', '<= 75'],
          point: 1
        },
        {
          conditions: ['>= 96', '<= 104'],
          point: 1
        },
        {
          conditions: ['>= 54', '<= 68'],
          point: 2
        },
        {
          conditions: ['>= 105', '<= 116'],
          point: 2
        },
        {
          conditions: ['< 54'],
          point: 3
        },
        {
          conditions: ['> 116'],
          point: 3
        },
      ],
    },
    {
      division: 20,
      range: [
        {
          conditions: ['>= 74', '<= 91'],
          point: 0
        },
        {
          conditions: ['>= 66', '<= 73'],
          point: 1
        },
        {
          conditions: ['>= 92', '<= 101'],
          point: 1
        },
        {
          conditions: ['>= 53', '<= 65'],
          point: 2
        },
        {
          conditions: ['>= 102', '<= 113'],
          point: 2
        },
        {
          conditions: ['< 53'],
          point: 3
        },
        {
          conditions: ['> 113'],
          point: 3
        },
      ],
    },
    {
      division: 21,
      range: [
        {
          conditions: ['>= 71', '<= 89'],
          point: 0
        },
        {
          conditions: ['>= 65', '<= 70'],
          point: 1
        },
        {
          conditions: ['>= 90', '<= 99'],
          point: 1
        },
        {
          conditions: ['>= 50', '<= 64'],
          point: 2
        },
        {
          conditions: ['>= 100', '<= 111'],
          point: 2
        },
        {
          conditions: ['< 50'],
          point: 3
        },
        {
          conditions: ['> 111'],
          point: 3
        },
      ],
    },
    {
      division: 22,
      range: [
        {
          conditions: ['>= 70', '<= 88'],
          point: 0
        },
        {
          conditions: ['>= 63', '<= 69'],
          point: 1
        },
        {
          conditions: ['>= 89', '<= 96'],
          point: 1
        },
        {
          conditions: ['>= 49', '<= 62'],
          point: 2
        },
        {
          conditions: ['>= 97', '<= 109'],
          point: 2
        },
        {
          conditions: ['< 49'],
          point: 3
        },
        {
          conditions: ['> 109'],
          point: 3
        },
      ],
    },
    {
      division: 23,
      range: [
        {
          conditions: ['>= 69', '<= 86'],
          point: 0
        },
        {
          conditions: ['>= 61', '<= 68'],
          point: 1
        },
        {
          conditions: ['>= 87', '<= 95'],
          point: 1
        },
        {
          conditions: ['>= 48', '<= 60'],
          point: 2
        },
        {
          conditions: ['>= 96', '<= 106'],
          point: 2
        },
        {
          conditions: ['< 48'],
          point: 3
        },
        {
          conditions: ['> 106'],
          point: 3
        },
      ],
    },
  ],
  // 収縮期血圧
  ppats_sbp: [
    {
      division: 0,
      range: '< 60',
    },
    {
      division: 1,
      range: '< 70',
    },
    {
      division: 2,
      range: '< 70',
    },
    {
      division: 3,
      range: '< 70',
    },
    {
      division: 4,
      range: '< 70',
    },
    {
      division: 5,
      range: '< 70',
    },
    {
      division: 6,
      range: '< 70',
    },
    {
      division: 7,
      range: '< 70',
    },
    {
      division: 8,
      range: '< 70',
    },
    {
      division: 9,
      range: '< 70',
    },
    {
      division: 10,
      range: '< 70',
    },
    {
      division: 11,
      range: '< 70',
    },
    {
      division: 12,
      range: '< 72',
    },
    {
      division: 13,
      range: '< 74',
    },
    {
      division: 14,
      range: '< 76',
    },
    {
      division: 15,
      range: '< 78',
    },
    {
      division: 16,
      range: '< 80',
    },
    {
      division: 17,
      range: '< 82',
    },
    {
      division: 18,
      range: '< 84',
    },
    {
      division: 19,
      range: '< 86',
    },
    {
      division: 20,
      range: '< 88',
    },
    {
      division: 21,
      range: '< 90',
    },
    {
      division: 22,
      range: '< 90',
    },
    {
      division: 23,
      range: '< 90',
    },
  ]
}