import * as api from "../api/MedicalRecordApi"
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from 'react-toastify'

const useMedicalRecord = (id) => {
  return useQuery(
    ['medical_record', id],
    () => api.getMedicalRecord(id),
    { enabled: id > 0 }
  )
}

const useCreateMedicalRecord = (props) => {
  const queryClient = useQueryClient()
  // console.log(props)
  return useMutation(api.createMedicalRecord, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('medical_record')
      // handleClose()
      toast.success('登録が成功しました')
    },
    onError: (error) => {
      toast.error('登録に失敗しました')
    },
  })
}

const useUpdateMedicalRecord = (id) => {
  const queryClient = useQueryClient()
  return useMutation(api.updateMedicalRecord, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('medical_record')
      toast.success('更新が成功しました')
    },
    onError: (error) => {
      toast.error('更新に失敗しました')
    },
  })
}

const useSelectOptions = () => {
  return useQuery('medical_record_select_options', () => api.getSelectOptions())
}

export {
  useMedicalRecord,
  useCreateMedicalRecord,
  useUpdateMedicalRecord,
  useSelectOptions,
}