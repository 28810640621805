import React, {useState,useEffect} from 'react';
import { useParams } from 'react-router-dom';
import GenericTemplate from '../templates/GenericTemplate'
import Triage from '../templates/Triage';
import {
  Paper, Grid,
} from '@mui/material'
import { DataGrid, GridToolbar, jaJP } from '@mui/x-data-grid';
import {format, parseISO} from 'date-fns'
import { usePatientList } from '../../queries/PatientQuery';

const List = () => {
  const params = useParams()
  const columns = [
    {
      field: 'c_at',
      headerName: '実施日',
      width: 160,
      valueFormatter: (params) => {
        if(params.value == null) {
          return ''
        }
        else {
          return format(parseISO(params.value), "yyyy/MM/dd HH:mm")
        }
      }
    },
    {
      field: 'start',
      headerName: 'START',
      width: 150,
      renderCell: (params) => {
        if(params.row.type === 'start')
          return <Triage color={params.row.triage_priority} />
        else
          return <></>
      } 
    },
    {
      field: 'ppats',
      headerName: 'PPATS',
      width: 150,
      renderCell: (params) => {
        if(params.row.type === 'ppats')
          return <Triage color={params.row.triage_priority} />
        else
          return <></>
      } 
    },
    {
      field: 'ppats_score',
      headerName: '（点数）',
      width: 80,
      valueGetter: (params) => {
        if(params.row.type === 'ppats')
          return params.row.score
        else
          return ''
      } 
    },
    {
      field: 'urgency',
      headerName: '緊急度判定',
      width: 150,
      renderCell: (params) => {
        if(params.row.type === 'urgency')
          return <Triage color={params.row.triage_priority} />
        else
          return <></>
      } 
    },
    {
      field: 'institution',
      headerName: '所属',
      width: 160,
    },
    {
      field: 'name',
      headerName: '実施者名',
      width: 120,
    },
    {
      field: 'role',
      headerName: '役職名',
      width: 100,
    },
  ]
  const [rows, setRows] = useState([])
  const { data, status } = usePatientList(params.id)

  useEffect(() => {
    console.log('data:',data, status)
    if(status === 'success'){
      setRows(data)
    }
  },[data])
  return (
    <GenericTemplate title="判定結果一覧">
      <Grid item xs={12}>
        <Paper sx={{p: 2}}>
          <DataGrid
            autoHeight
            components={{ Toolbar: GridToolbar }}
            rows={rows}
            columns={columns}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          />
        </Paper>
      </Grid>
    </GenericTemplate>

  )
}

export default List