import axios from "axios";
import { config } from '../lib/config'

const getUser = async () => {
  const { data } = await axios.get(`${config.api_host}/user`)
  return data
}
const login = async ({loginid, password}) => {
  await axios.get(`${config.api_host}/sanctum/csrf-cookie`)
  const { data } = await axios.post(`${config.api_host}/login`, { loginid, password }, { withCredentials: true })
  return data
}
const logout = async () => {
  const { data } = await axios.post(`${config.api_host}/logout`)
  return data
}

export {
  getUser,
  login,
  logout
}