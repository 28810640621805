import * as api from "../api/UrgencyApi"
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from 'react-toastify'

const useUrgencies = (id) => {
  return useQuery(['urgencies', id], () => api.getUrgencies(id))
}

const useUrgency = (id) => {
  return useQuery(
    ['urgency', id],
    () => api.getUrgency(id),
    { enabled: id > 0 }
  )
}

const useCreateUrgency = (props) => {
  const queryClient = useQueryClient()
  return useMutation(api.createUrgency, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('urgencies')
      queryClient.invalidateQueries('patient_state')
      toast.success('登録が成功しました')
    },
    onError: (error) => {
      toast.error('登録に失敗しました')
    },
  })
}

const useUpdateUrgency = (id) => {
  const queryClient = useQueryClient()
  return useMutation(api.updateUrgency, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('urgencies')
      queryClient.invalidateQueries('patient_state')
      toast.success('更新が成功しました')
    },
    onError: (error) => {
      toast.error('更新に失敗しました')
    },
  })
}

export {
  useUrgencies,
  useUrgency,
  useCreateUrgency,
  useUpdateUrgency,
}